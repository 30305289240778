/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line import/no-cycle
import { CategNode, CategBase, Product, Metadata, ProdMetadatas, SelectFilter, SelectOption } from './_prodSlice';
import { formatNumber, slugify } from '../common/utils/utils';
import { lang } from '../app/globals';

export function buildCategTree(
  categs: CategBase[], initialIdx: number, parentNode: CategNode, parentKey: string, level: number,
) : { idx: number, cantProd: number } {

  let idx = initialIdx;
  let currentCateg = categs[idx];
  let currentKey = currentCateg.cod;

  if (level < currentKey.length) {
    // insert missing category
    categs.splice(idx, 0, { cod: currentKey.substring(0, level), cantProd: 0 });
    currentCateg = categs[idx];
    currentKey = currentCateg.cod;
  }
  const currentNode = { cod: currentKey, children: {}, cantProd: 0 };

  let { cantProd } = currentCateg; // start with the row cantProd (may be non zero in leafs)

  // otherwise stay on the same row (if missing category)

  if (idx < categs.length - 1) {
    let nextCateg = categs[idx + 1];
    let nextKey = nextCateg.cod;
    while (nextKey.startsWith(currentKey)) {
      const tuple = buildCategTree(categs, idx + 1, currentNode, currentKey, level + 1);
      cantProd += tuple.cantProd;
      idx = tuple.idx;
      if (idx >= categs.length - 1) {
        break;
      }
      nextCateg = categs[idx + 1];
      nextKey = nextCateg.cod;
    }

  }
  if (cantProd > 0) { // do not add categories with no products
    currentNode.cantProd = cantProd;
    parentNode.children[currentKey] = currentNode;
    parentNode.cantProd = cantProd;
  }
  return { idx, cantProd };
}

export function buildProdsFilters(prods: Product[], metadatas : Metadata[], prodMetadatas: ProdMetadatas[])
  : SelectFilter[] {

  const marcaOptions: SelectOption[] = [];

  prods.forEach((p, idx) => {
    if (marcaOptions.length === 0) {
      marcaOptions[idx] = { id: idx, key: slugify(p.marca), label: p.marca.trim(), value: false };
    } else if (!marcaOptions.some((x) => x.label === p.marca.trim())) {
      marcaOptions[idx] = { id: idx, key: slugify(p.marca), label: p.marca.trim(), value: false };
    }
  });

  // common filters (no metadata)
  const filterList :SelectFilter[] = [
    { name: 'Marca',
      type: 'T',
      hasTextInput: true,
      options: marcaOptions },
    { name: 'Precio',
      type: 'N',
      hasTextInput: false,
      options: [
        { id: 1, key: '0-100', label: 'MENOR A $100', value: false },
        { id: 2, key: '101-500', label: '$101 - $500', value: false },
        { id: 3, key: '501-2000', label: '$501 - $2000', value: false },
        { id: 4, key: '2001-10000', label: '$2001 - $10000', value: false },
        { id: 5, key: '10001-50000', label: '$10001 - $50000', value: false },
        { id: 6, key: '50001-100000', label: '$50001 - $100000', value: false },
      ] },
  ];

  metadatas.forEach((m) => {

    if (m.tipo === 'N') {
      // Range
      const metadatasValues: number[] = [...new Set(
        prodMetadatas.filter((f) => f.idMetadata === m.id).map((f) => f.numericValue as number),
      )];

      if (metadatasValues.length > 1) {
        metadatasValues.sort((a, b) => a - b);

        if (metadatasValues[0] !== metadatasValues[metadatasValues.length - 1]) {
          let zerosCount = 0;
          metadatasValues.forEach((n) => {
            if (Math.floor(n) !== n && n.toString().split('.')[1].length > zerosCount) {
              zerosCount = n.toString().split('.')[1].length;
            }
          });

          const middleCount: number = metadatasValues.length > 2
            ? Math.round(metadatasValues.length * (1 / 2)) : metadatasValues.length;
          metadatasValues.splice(1, metadatasValues.length - 2);

          const middleValue: number = parseFloat(
            ((metadatasValues[1] - metadatasValues[0]) * (1 / middleCount)).toFixed(zerosCount),
          );

          for (let i = 1; i < middleCount; i += 1) {
            metadatasValues.push(metadatasValues[0] + (middleValue * i));
          }
          metadatasValues.push(metadatasValues[1]);
          metadatasValues.splice(1, 1);
          if (metadatasValues[metadatasValues.length - 1] === metadatasValues[metadatasValues.length - 2]) {
            metadatasValues.splice(metadatasValues.length - 1, 1);
          }

          const options: SelectOption[] = [];

          metadatasValues.forEach((n, idx) => {
            if (idx === 0) {
              options.push({
                id: idx,
                key: `${formatNumber(n, lang)}-${formatNumber(metadatasValues[idx + 1], lang)}`,
                label: `${formatNumber(n, lang)}-${formatNumber(metadatasValues[idx + 1], lang)}`,
                value: false,
              });
            } else if (idx < metadatasValues.length - 1) {
              const n2 = zerosCount > 0
                ? n + parseFloat('0.' + '0'.repeat(zerosCount - 1) + '1')
                : n + 1;
              options.push({
                id: idx,
                key: `${formatNumber(n2, lang)}-${formatNumber(metadatasValues[idx + 1], lang)}`,
                label: `${formatNumber(n2, lang)}-${formatNumber(metadatasValues[idx + 1], lang)}`,
                value: false,
              });
            }
          });
          filterList.push({ options, hasTextInput: false, name: m.nombre, type: 'N' });
        }
      }
    } else if (m.tipo === 'T') {
      // Select
      const metadatasValues = prodMetadatas.filter((f) => f.idMetadata === m.id)
        .map((f) => f.textValue);

      const options = ([...new Set(metadatasValues)]
        .map((x, idx) => ({
          id: idx,
          key: `${slugify(x)}`,
          label: x,
          value: false,
        } as SelectOption)) as Array<SelectOption>);

      filterList.push({ options, hasTextInput: false, name: m.nombre, type: 'T' });
    }
  });

  return filterList;
}
