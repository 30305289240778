/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentLoader from 'react-content-loader';

const DefaultLoader = ({ count } : any) => {
  const items = Array.from('x'.repeat(count || 12));
  return (
    <section className="CategoryList">
      <div className="container">
        {items.map((elem, index) => (
          <ContentLoader
            key={index}
            speed={2}
            width={380}
            height={280}
            viewBox="0 0 300 280"
            backgroundColor="#d8d8d8"
            foregroundColor="#cccccc"
          >
            <rect x="00" y="30" rx="3" ry="3" width="50" height="50" />
            <rect x="70" y="32" rx="3" ry="3" width="120" height="9" />
            <rect x="70" y="60" rx="3" ry="3" width="90" height="9" />
            <rect x="00" y="96" rx="3" ry="3" width="270" height="9" />
            <rect x="00" y="122" rx="3" ry="3" width="270" height="9" />
            <rect x="00" y="148" rx="3" ry="3" width="178" height="9" />
            <rect x="00" y="176" rx="3" ry="3" width="270" height="9" />
            <rect x="00" y="202" rx="3" ry="3" width="240" height="9" />
            <rect x="00" y="228" rx="3" ry="3" width="180" height="9" />
          </ContentLoader>
        ))}
      </div>
    </section>
  );
};

export default DefaultLoader;
