/* eslint-disable no-bitwise */
import React from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { Redirect } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Categ, CategNode } from '../../prod/_prodSlice';
import { lang } from '../../app/globals';
import { bgImageStyle, slugify, useQuery } from '../../common/utils/utils';
import { Breadcrumb, BreadcrumbContent } from '../../common/components/Breadcrumb';
import '../CardGalleryPanel.scss';

export default function CategoryList({ categNode, categDict, bc, message }
:{ categNode:CategNode, categDict:Dictionary<Categ>, bc:BreadcrumbContent, message: string | undefined }) {

  const categs = Object.entries(categNode.children);
  const fullQuery = useQuery();
  const search = fullQuery.get('q');
  const searchStr = search ? `?q=${search}` : '';
  const force = fullQuery.get('force');

  const currentItem = (categDict as Dictionary<Categ>)[categNode.cod] as Categ;

  if (categs.length === 1 && force !== '1') {
    const [key] = categs[0];
    const item = (categDict as Dictionary<Categ>)[key] as Categ;
    return <Redirect to={`/${lang}/catalog/${item.cod}~${slugify(item.nombre)}${searchStr}`} />;
  }

  return (
    <section className="CategoryList" id="catalog">
      {message && (
      <div className="container">
        <h4 className="text-danger text-center">{message}</h4>
        <p>Seleccione entre las siguientes categorías:</p>
      </div>
      )}
      {bc.urls.length > 0 && !message && (
      <Breadcrumb urls={bc.urls} names={bc.names} />
      )}
      <div className="container">
        <div className="panel__heading d-flex flex-row">
          <div className="panel__title flex-grow-1 title-01">
            {currentItem.cod === 'a' ? 'Categorías' : currentItem.nombre}
          </div>
        </div>
        <div className={`panel__cards d-flex 
          ${categs.length < 3 || (categs.length % 3 === 0 && categs.length % 4 !== 0)
          ? 'three-per-row' : 'four-per-row'}`}
        >
          {categs.map((entry) => {
            const [key, value] = entry;
            const item = (categDict as Dictionary<Categ>)[key] as Categ;
            const idDigitsCount = item.id.toString().length;

            return (
              <div className="card" key={item.id}>
                <Link to={`/${lang}/catalog/${item.cod}~${slugify(item.nombre)}`}>
                  <div className="card__title">{item.nombre}: {value.cantProd}</div>
                  <div
                    className="card__image"
                    style={bgImageStyle(item.imagen ? '/dataImages/' + item.imagen
                      : '/dataImages/categorias/CA' + '0'.repeat(5 - idDigitsCount) + item.id.toString() + '.jpg')}
                />
                </Link>
              </div>
            );
          })}
        </div>
        <div className="panel__bottom-bar flex-row" />
      </div>
    </section>
  );

}
