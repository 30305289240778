import { useLocation } from 'react-router';

/**
 * Build style object for background image, fixed to work on different environments
 */
export function bgImageStyle(imagePath: string) {
  if (!imagePath) {
    return {};
  }
  const newPath = imagePath.startsWith('/media') || imagePath.startsWith('/dataImages')
    ? (process.env.REACT_APP_BACKEND_URL || '') + imagePath
    : (process.env.PUBLIC_URL || '') + imagePath;

  return {
    backgroundImage: `url(${newPath})`,
    opacity: 1,
  };
}

/**
 * Build URL for media item (image or file), fixed to work on different environments
 */
export function mediaUrl(imagePath: string) {
  if (!imagePath) {
    return '';
  }
  const newPath = imagePath.startsWith('/media') || imagePath.startsWith('/dataImages')
    ? (process.env.REACT_APP_BACKEND_URL || '') + imagePath
    : (process.env.PUBLIC_URL || '') + imagePath;
  return newPath;
}

/**
 * Convert string to lower-case, with no accented characters (transform) and no special characters (remove)
 * i.e. "Qué es?" --> "que es"
 */
export function toLowerAscii(text: string) {
  return text.toLowerCase();
}

export function slugify(name: string) {
  let slug = name.replace(/^\s+|\s+$/g, ''); // trim
  slug = slug.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  slug = slug.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return slug;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function formatDateTime(value:string) {
  if (!value) {
    return '';
  }
  const date2 = typeof value === 'string' ? new Date(value) : value;
  return ('0' + date2.getDate()).slice(-2)
    + '/' + ('0' + (date2.getMonth() + 1)).slice(-2)
    + '/' + date2.getFullYear();
}

export function formatNumber(number: number | string, lang:string, isCurrency:boolean = false,
  decimals: number = 2) {
  if (!Number.isNaN(number)) {
    if (isCurrency) {
      return (
        new Intl.NumberFormat(lang, { style: 'currency', currency: 'USD', maximumFractionDigits: decimals })
          .format(Number((Math.round(number as number * 100) / 100)))
      ).slice(0, -3);
    }
    return (
      new Intl.NumberFormat(lang, { maximumFractionDigits: decimals })
        .format(Number((Math.round(number as number * 100) / 100)))
    );
  }
  return 0;
}
