// define allowed and default language
const allowedLanguages = ['es'];
export const defaultLang = 'es';

// get current language from path and store it in a global variable
// This is ok because if the first path segment changes the app will be fully reloaded
// (no soft navigation between languages)
const pathSegments = window.location.pathname.split('/').filter((x) => x);
export const lang = pathSegments.length === 0 ? defaultLang : pathSegments[0];
// Note: the root URL '/' gets a soft redirect later (using the Router component)

// invalid language? force page reload to the default language
if (!allowedLanguages.includes(lang)) {
  window.location.href = '/' + defaultLang;
}

// required texts - needed before loading terms
export const errorPrefix = lang === 'es' ? 'No se pudo completar la operación' : 'Unable to complete the operation';

// build and keep global URLs
export const siteUrl = process.env.REACT_APP_BACKEND_URL || '';
export const apiUrl = siteUrl + '/api/site';

// configure authorization storage
export const authorizationStorage : Storage = localStorage; // localStorage -> persist between sessions
export const authStorageKey = 'siteAuth';
export const authStorageXorMask = 0x48751236;

// identify page elements
export const rootElement = document.getElementById('root');
export const statePreloadScript = document.getElementById('state-preload');

// status of a fetch operation
export enum RequestStatus {
  None,
  Waiting,
  Ready,
}
