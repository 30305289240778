/* eslint-disable dot-notation */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { RootState, useAppSelector } from '../../app/store';
import { SiteInfo } from '../../_types/siteInfoTypes';
import './ConfirmModal.scss';

function ConfirmModal(props: any) {
  const { onClickCancel, onClickConfirm, title, body, show, hasContinue } = props;

  const {
    siteInfo,
  } : {
    siteInfo: SiteInfo;
  } = useAppSelector((state: RootState) => ({
    siteInfo: state.content.siteInfo,
  }));

  const { terms } = siteInfo;

  return (
    <Modal
      className="confirm-modal"
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={onClickCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {body && (
        <Modal.Body>{body}</Modal.Body>
      )}
      <Modal.Footer>
        {!hasContinue ? (
          <>
            <button
              type="button"
              onClick={onClickConfirm}
              className="btn btn--primary type--uppercase"
            >
              <span className="btn btn-danger">{terms['confirm']}</span>
            </button>
            <button
              type="button"
              className="btn type--uppercase"
              onClick={onClickCancel}
            >
              <span className="btn btn-dark">{terms['cancel']}</span>
            </button>
          </>
        ) : (
          <button
            type="button"
            className="btn type--uppercase"
            onClick={onClickCancel}
          >
            <span className="btn btn-danger">{terms['continue']}</span>
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
