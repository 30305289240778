/* eslint-disable no-bitwise */
import React from 'react';
import { Link } from 'react-router-dom';
import { RootState, useAppSelector } from '../app/store';
import { SiteInfo } from '../_types/siteInfoTypes';
import { Categ, ProdState } from '../prod/_prodSlice';
import { lang } from '../app/globals';
import './Footer.scss';
import { UserState } from '../user/_userSlice';
import Icon from '../common/components/Icon';
import { slugify } from '../common/utils/utils';

function Footer() {

  const {
    siteInfo,
    prodInfo,
    userInfo,
  } : {
    siteInfo: SiteInfo;
    prodInfo: ProdState;
    userInfo: UserState;
  } = useAppSelector((state: RootState) => ({
    siteInfo: state.content.siteInfo,
    prodInfo: state.prod,
    userInfo: state.user,
  }));

  return (
    <footer className="Footer">
      <div className="container">
        <div className="row no-gutters">
          { userInfo && userInfo.isAuthenticated && (
          <>
            <div className="col-sm column">
              <div className="column__title">{siteInfo.footer.menuTitle}</div>
              {siteInfo.footer.footerLinks
                && siteInfo.footer.footerLinks.map((lnk: any) => (
                  <Link to={lnk.url} key={lnk.text}
                    className={`link column__link ${lnk.separator ? 'has-padding' : ''}`}
                  >
                    {lnk.text}
                  </Link>
                ))}
            </div>
            <div className="col-sm column">
              <div className="column__title">{siteInfo.footer.categoriesTitle}</div>
              {prodInfo.featuredCategs
                && prodInfo.featuredCategs.map((cat: Categ) => (
                  <Link to={`/${lang}/catalog/${cat.cod}~${slugify(cat.nombre)}`} key={cat.id}
                    className="link column__link"
                  >
                    {cat.nombre}
                  </Link>
                ))}
            </div>
          </>
          )}

          <div className="col-sm column">
            <div className="column__title">{siteInfo.contact.title}</div>
            <div><Icon type="marker" width={12} height={12} />{siteInfo.contact.address}</div>
            <div className="phone">
              <Icon type="speech-bubble" width={12} height={12} />{siteInfo.contact.phone}
            </div>
            <div><Icon type="arroba" width={12} height={12} />{siteInfo.contact.email}</div>
          </div>
        </div>
      </div>
      <div className="no-gutters row bottom-bar">
        <div className="container">

          <div>
            <span className="font-weight-bold copyright">{siteInfo.footer.copyright}</span>
            <span className="vertical-separator">|</span> <br />
            <span>{siteInfo.footer.copyright2}</span>
            <span className="vertical-separator">|</span> <br /> <br />
            <span><a href={siteInfo.footer.termsUrl} className="link">{siteInfo.footer.termsCopy}</a></span>
          </div>
          <div className="logo">
            <Icon type="bileran-logo" width={24} height={24} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
