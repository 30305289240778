/* eslint global-require: "off" */
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import rootReducer from './rootReducer';
import { statePreloadScript } from './globals';

let preloadedState: any;
const json = statePreloadScript ? statePreloadScript.innerText.trim() : '';
if (json.match(/^{/m)) {
  preloadedState = JSON.parse(json);
  if (preloadedState.content) {
    preloadedState.content.isLoaded = true;
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
  // .prepend(
  //   // correctly typed middlewares can just be used
  //   additionalMiddleware,
  //     // you can also type middlewares manually
  //     untypedMiddleware as Middleware<
  //       (action: Action<'specialAction'>) => number,
  //       RootState
  //     >,
  // ),
  // // prepend and concat calls can be chained
  //   .concat(logger),
  preloadedState,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}
// type for use in useSelector(state: RootState)
export type RootState = ReturnType<typeof rootReducer>;

// custom hook for useSelector
export const useAppSelector = (expression: any) => useSelector((state: RootState) => (expression(state)), shallowEqual);

// export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

// the store
export default store;
