import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dictionary } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { RootState, useAppDispatch, useAppSelector } from '../app/store';
import { PanelInfo } from '../_types/pageTypes';
import { CategNode, Categ, getAllCategories, getSearchCategories, ProdState, SortFilter } from '../prod/_prodSlice';
import CategoryList from './Catalog/CategoryList';
import ProductList from './Catalog/ProductList';
import ProductDetail from './Catalog/ProductDetail';
import { slugify, useQuery } from '../common/utils/utils';
import { BreadcrumbContent } from '../common/components/Breadcrumb';
import DefaultLoader from '../common/loaders/DefaultLoader';
import { RequestStatus, lang } from '../app/globals';
import { CatalogPanelInfo } from '../_types/catalogPanelTypes';
import { Term } from '../_types/siteInfoTypes';

interface CatalogPanelContent extends CatalogPanelInfo {}

function getCategsBreadcrumb(codCateg: string, categDict:Dictionary<Categ>, segmentsCount: number) {
  const bc:BreadcrumbContent = { urls: [], names: [] };
  const slashesBack:string = segmentsCount > 3 ? '../'.repeat(segmentsCount - 3) : '';

  if (codCateg.length > 1) {
    let prevCod = codCateg;
    prevCod = prevCod.slice(0, -1);
    while (prevCod.length >= 2) {
      const item = (categDict as Dictionary<Categ>)[prevCod] as Categ;
      if (item) {
        bc.urls.push(`${slashesBack}${item.cod}~${slugify(item.nombre)}?force=1`);
        bc.names.push(`${item.nombre}`);
      }
      prevCod = prevCod.slice(0, -1);
    }
    bc.urls.push(`${slashesBack}../../${lang}/catalog`);
    bc.names.push('CATEGORÍAS');
    bc.urls = bc.urls.reverse();
    bc.names = bc.names.reverse();
  }

  return bc;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CatalogPanel({ panel }: {
  panel: PanelInfo<CatalogPanelContent>
}) {
  const { pathname } = useLocation();

  const segments = pathname.split('/').filter((x) => x);
  const search = useQuery().get('q');

  // const cod = search ? 'a' : (segments.length > 2 ? segments[segments.length - 1].split('~')[0] : 'a');
  const prodId:number = segments.length > 3 && !Number.isNaN(segments[segments.length - 1])
    ? parseInt(segments[segments.length - 1]) : 0;
  const cod = segments.length > 2 ? segments[segments.length - 1 - (segments.length > 3 ? 1 : 0)].split('~')[0] : 'a';

  const {
    prodInfo,
    terms,
  } : {
    prodInfo: ProdState;
    terms: Term[];
  } = useAppSelector((state: RootState) => ({
    prodInfo: state.prod,
    terms: state.content.siteInfo.terms,
  }));

  const { categDict, categTree, categTreeStatus,
    searchQuery, searchTree, searchTreeStatus } = prodInfo;

  const newSearch = search && search !== searchQuery && searchTreeStatus !== RequestStatus.Waiting && search;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (categTreeStatus === RequestStatus.None) {
      dispatch(getAllCategories())
        .then((response: any) => {
          if (response.error) {
            toast.error(`${terms['unknownError']}: ${response.error.message} (getAllCategories / ${response.error.code})`);
          }
        });
    }
  }, [categTreeStatus, dispatch]);

  useEffect(() => {
    if (newSearch) {
      dispatch(getSearchCategories(newSearch))
        .then((response: any) => {
          if (response.error) {
            toast.error(`${terms['unknownError']}: ${response.error.message} (getSearchCategories / ${response.error.code})`);
          }
        });
    }
  }, [newSearch, dispatch]);

  if (categTreeStatus !== RequestStatus.Ready
    || (!categTree.cantProd)
    || (search && search !== searchQuery)) {
    return (<DefaultLoader />);
  }

  if (segments.length > 3) {
    if (prodId > 0) {
      const bc:BreadcrumbContent = getCategsBreadcrumb(`${cod}x`, categDict, segments.length);
      return (<ProductDetail prodId={prodId} bc={bc} />);
    }
  }

  const node = getDescendant(search && searchTree.cantProd > 0 ? searchTree : categTree, '', cod);

  const hasProducts = ((categDict as Dictionary<Categ>)[cod] as Categ).cantProd > 0;

  // get urls for breadcrumb component
  const bc:BreadcrumbContent = getCategsBreadcrumb(cod, categDict, segments.length);

  if (hasProducts) {
    const categ:Categ = (categDict as Dictionary<Categ>)[cod] as Categ;

    const sortFilter:SortFilter = { options: [] };

    panel.content.sortFilterOptions
      .forEach((x, idx) => (sortFilter.options
        .push({ id: idx + 1, key: slugify(x.text), label: x.text, value: idx === 0 })));

    return (
      <ProductList categ={categ} bc={bc} sortFilter={sortFilter} />
    );
  }
  const message = search && searchTree.cantProd === 0 ? 'No se encontraron productos' : '';
  return (
    <CategoryList categNode={node} categDict={categDict} bc={bc} message={message} />
  );
}

const getDescendant = (parentNode: CategNode, parentKey: string, targetKey: string):
CategNode => {

  const currentKey = targetKey.substring(0, parentKey.length + 1);
  const currentNode = parentNode.children[currentKey];
  if (currentNode?.cod === targetKey) {
    return currentNode;
  }
  if (!currentNode?.children) {
    return parentNode;
  }
  return getDescendant(currentNode, currentKey, targetKey);
};
