import axios from 'axios';
import { AuthInfo, checkTokenExpiration, getAuthInfo, setAuthInfo } from './authStorage';

const httpClient = async (url: any, options: any = {}, action?: string, data?: any) : Promise<any> => {
  if (!options.headers) {
    options.headers = { accept: 'application/json', 'content-type': 'application/json' };
  }
  const authInfo = getAuthInfo();
  if (authInfo && checkTokenExpiration(authInfo)) {
    options.headers.authorization = `Bearer ${(authInfo as AuthInfo).jwtToken}`;
  }

  if (!options.timeout) {
    options.timeout = 20480;
  }
  let response: any;

  switch (action) {
    case 'POST': {
      response = await axios.post(url, data, options);
      break;
    }
    case 'DELETE': {
      response = await axios.delete(url, options);
      break;
    }
    case 'PUT': {
      response = await axios.put(url, data, options);
      break;
    }
    // case GET
    default: {
      response = await axios.get(url, options);
    }
  }
  // Check refresh token
  const newToken = response.headers['set-authorization'];
  if (newToken && authInfo) {
    setAuthInfo(authInfo, newToken);
  }
  return response;
};

export default httpClient;
