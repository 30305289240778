/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { Redirect, useLocation } from 'react-router';
import axios from 'axios';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppSelector, RootState, useAppDispatch } from './store';
import { forceNewLogin } from '../user/_userSlice';
import useInterval from '../common/utils/useInterval';
import { validateCurrentToken } from '../common/utils/authStorage';

export default function ForceLoginTimer() {

  const {
    isAuthenticated,
  } : {
    isAuthenticated: boolean,
  } = useAppSelector((state: RootState) => ({
    isAuthenticated: state.user.isAuthenticated,
  }));

  const [restartInterval, setRestartInterval] = useState(isAuthenticated);

  // get the dispatcher
  const dispatch = useAppDispatch();

  // interval handler
  const handleInterval = () => {
    const expirationMsec = validateCurrentToken();
    if (expirationMsec) {
      // not expired: reset with new interval
      console.log('handleInterval: setRestartInterval(true)');
      setRestartInterval(true); // this forces the App render
    } else {
      // token expired - force login
      console.log('handleInterval: force login');
      dispatch(forceNewLogin());
    }
  };

  const expirationMsec = validateCurrentToken();
  console.log('Checking token', !!expirationMsec, (new Date()).toString());
  // set or reset timer to show login dialog
  // set to 5 seconds before token expiration to avoid user click when expiring
  useInterval(handleInterval, expirationMsec ? expirationMsec - Date.now() - 5000 : null);

  return null;
}
