/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { defaultLang, RequestStatus, lang } from '../app/globals';
import { RootState, useAppDispatch, useAppSelector } from '../app/store';
import { SiteInfo } from '../_types/siteInfoTypes';
import { PageInfo } from '../_types/pageTypes';
import Header from './Header';
import PanelWrapper from './PanelWrapper';
import Cart from './Cart';
import Footer from './Footer';
import { UserState, getUserData, logout } from '../user/_userSlice';
import DefaultLoader from '../common/loaders/DefaultLoader';
import toastIfError from '../common/utils/toastIfError';
import LoginDialog from './LoginDialog';

export default function Page() {

  const { pathname } = useLocation();
  const [, pathLang, rest] = pathname.match(/\/([^/]+)(?:\/(.*))?/) || ['', undefined, undefined];
  let slug = rest ? (rest?.replaceAll('/', '_')) : 'home'; // page slug i.e. 'home', 'catalog'
  if (slug.startsWith('catalog')) {
    slug = 'catalog';
  } else if (slug.startsWith('mis-presupuestos')) {
    slug = 'mis-presupuestos';
  } else if (slug.startsWith('mis-ordenes')) {
    slug = 'mis-ordenes';
  }

  const {
    siteInfo,
    pageInfo,
    userInfo,
  } : {
    siteInfo: SiteInfo;
    pageInfo: PageInfo,
    userInfo: UserState,

  } = useAppSelector((state: RootState) => ({
    siteInfo: state.content.siteInfo,
    pageInfo: state.content.pages[slug],
    userInfo: state.user,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userInfo.isAuthenticated && userInfo.userDataStatus === RequestStatus.None) {
      dispatch(getUserData())
        .then(toastIfError('getUserData', { onError: () => dispatch(logout()) }));
    }
  }, [userInfo]);

  if (!pathLang) {
    return <Redirect to={'/' + defaultLang} />;
  }

  if (pathLang !== lang) {
    // This shouln't have happened: don't change languages using a soft navigation.
    // We will now fire a hard reload to the default language
    window.location.href = '/' + lang;
  }

  if (!pageInfo) {
    return (<DefaultLoader />);
  }

  if (!userInfo.isAuthenticated) {
    return (
      <>
        <Header />
        <LoginDialog content={siteInfo.loginContent} />
        <Footer />
      </>
    );
  }

  if (userInfo.isCartOpen && siteInfo) { // show cart
    return (
      <>
        <Header />
        <Cart />
      </>
    );
  }

  return (
    <>
      {(!pageInfo || !pageInfo.suppressHeader) && <Header />}

      {pageInfo.panels.map((panel, index) => (
        <PanelWrapper key={index} {...{ siteInfo, pageInfo, panel }} />
      ))}

      {(!pageInfo || !pageInfo.suppressFooter) && <Footer />}
    </>
  );
}
