import React from 'react';
import './SimpleTextPanel.scss';
import { PanelInfo } from '../_types/pageTypes';
import { SimpleTextPanelProps } from '../_types/simpleTextPanelTypes';

interface SimpleTextPanelContent extends SimpleTextPanelProps {}

export default function SimpleTextPanel({ panel }: {
  panel: PanelInfo<SimpleTextPanelContent>
}) {
  const { content } = panel;
  return (
    <section className="SimpleTextPanel panel">
      <div className="container">
        <div className="panel__title">{content.title}</div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="panel__body" dangerouslySetInnerHTML={{ __html: content.body }} />
      </div>
    </section>
  );
}
