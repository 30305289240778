/* eslint-disable react/require-default-props */
import React from 'react';

const iconBasePath:string = process.env.PUBLIC_URL + '/icons/';

interface IconProps {
  type: string;
  width?: number;
  height?: number ;
  className?: string;
}

const Icon = ({ type, width, height, className } : IconProps) => (
  <img src={iconBasePath + type + '.svg'} alt={`Icon ${type}`}
    width={width || 40} height={height || 40} className={className} />
);

export default Icon;
