/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { SerializedError } from '@reduxjs/toolkit';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { useAppSelector, useAppDispatch, RootState } from './store';
import { getContent } from './_contentSlice';
import Page from '../page/Page';
import DefaultLoader from '../common/loaders/DefaultLoader';
import ScrollToTop from '../common/components/ScrollToTop';
import { RequestStatus } from './globals';
import toastIfError from '../common/utils/toastIfError';
import { Categ, getFeaturedCategories } from '../prod/_prodSlice';
import ForceLoginTimer from './ForceLoginTimer';

/* ====================
  Global axios interceptor
    Handles error return
  ====================== */
axios.interceptors.response.use((response) => response, (error) => {
  if (!error.response) {
    const error5xx: SerializedError = { message: error.toString(), code: '500' };
    return Promise.reject(error5xx);
  }
  const code = (error.response.status || 400).toString();
  const message = typeof error.response.data === 'string' ? error.response.data
    : error.response.data?.title || error.response.statusText || 'Unknown error';
  const error4xx: SerializedError = { message, code };
  return Promise.reject(error4xx);
});

/**
 * The App is the root component of the site, only rendered once at boot.
 */
export default function App() {

  const {
    getContentStatus,
    isLoaded,
    featuredCategs,
    featuredCategsStatus,
  } : {
    getContentStatus: RequestStatus;
    isLoaded: boolean;
    featuredCategs: Categ[];
    featuredCategsStatus: RequestStatus;
  } = useAppSelector((state: RootState) => ({
    getContentStatus: state.content.getContentStatus,
    isLoaded: state.content.isLoaded,
    featuredCategs: state.prod.featuredCategs,
    featuredCategsStatus: state.prod.featuredCategsStatus,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoaded && getContentStatus === RequestStatus.None) {
      dispatch(getContent())
        .then(toastIfError('getContent'));
    }
  }, []);

  useEffect(() => {
    if (!featuredCategs.length && featuredCategsStatus === RequestStatus.None) {
      dispatch(getFeaturedCategories())
        .then(toastIfError('getFeaturedCategs'));
    }
  }, [featuredCategs, featuredCategsStatus]);

  if (!isLoaded || !featuredCategs.length) {
    return (
      <>
        <DefaultLoader />
        <AppToaster />
      </>
    );
  }
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ForceLoginTimer />
      <Page />
      <AppToaster />
    </BrowserRouter>
  );
}

function AppToaster() {
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: Infinity,
        style: {
          background: '#363636',
          color: '#fff',
          minWidth: '70%',
        },
        icon: null,
        // Default options for specific types
        success: {
          theme: {
            primary: 'green',
            secondary: 'black',
          },
        },
        error: {
          style: {
            background: '#ff7070',
            color: '#fff',
          },
          theme: {
            primary: 'red',
            secondary: 'black',
          },
        },

      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
              <button type="button" className="btn btn-outline-light"
                onClick={() => toast.dismiss(t.id)}
              ><strong>X</strong>
              </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
