/*
  This code is shared between admin and site projects
*/
/* eslint-disable no-bitwise */
import jwt from 'jsonwebtoken';
import { authorizationStorage, authStorageKey, authStorageXorMask } from '../../app/globals';

export enum AppRoles {
  None = 0,
  Admin = 1,
  BofUser = 2,
}

export interface AuthInfo {
  roles: number;
  jwtToken: string;
  username: string;
}
export interface UserLogin extends AuthInfo {
  email: string;
  userId: number;
}

export const getAuthInfo = () : AuthInfo | null => {
  const authData = authorizationStorage.getItem(authStorageKey);
  return authData ? parseAuthData(authData) : null;
};

export const setAuthInfo = (authInfo: AuthInfo, newToken: string | null = null) :
void => {
  if (newToken) {
    authInfo.jwtToken = newToken;
  }
  authorizationStorage.setItem(authStorageKey, infoToAuthStorage(authInfo));
};

export const removeAuthData = () : void => {
  authorizationStorage.removeItem(authStorageKey);
};

export function infoToAuthStorage(authInfo: AuthInfo) : string {
  // mask the roles value and add a big constant to get always the same length (7 bytes) when converting to base 36
  const n = (authInfo.roles ^ authStorageXorMask) + 0x100000000;
  // convert to base 36
  const s = n.toString(36);
  // append 7 bytes to the token - no separator.
  return authInfo.jwtToken + s + '$' + authInfo.username;
}

export function parseAuthData(authData: string) : AuthInfo {
  const [data, username] = authData.split('$');
  const len = data.length;
  const roles = (parseInt(data.substring(len - 7), 36) - 0x100000000) ^ authStorageXorMask;
  return {
    roles,
    jwtToken: data.substring(0, data.length - 7),
    username,
  };
}

/**
 * Get auth info and check token expiration
 * @returns expiration time in miliseconds since 1/1/1970, or 0 if token is missing / expired
 */
export function validateCurrentToken() : number {
  return checkTokenExpiration(getAuthInfo());
}

/**
 * Check token expiration in the specified auth info
 * @param authInfo authorization info
 * @returns expiration time in miliseconds since 1/1/1970, or 0 if token is missing / expired
 */
export function checkTokenExpiration(authInfo: AuthInfo | null) : number {
  const { jwtToken } = authInfo || {};
  if (jwtToken) {
    const { exp } = (jwt.decode(jwtToken) || {}) as jwt.JwtPayload;
    if (exp) {
      const expMSec = exp * 1000;
      if (expMSec > Date.now() + 10) {
        // return expiration time in miliseconds since 1/1/1970
        return expMSec;
      }
    }
  }
  return 0; // missing or expired token
}
