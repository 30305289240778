/*
    This block of code uses Webpack custom methods (context) - not ES6 imports
    to include all components in a folder and storing them by key in the 'panels' object

    It might stop working in some future cra version   :-/

    The forEach loop is equivalent to performing the following on each individual component:
        panels['HeroPanel'] = require('../../panels/HeroPanel').default;
 */
const panels = {};
const context = require.context('../panels/', true, /Panel\..sx$/);
context.keys().forEach((key) => {
  const panelType = key.substr(2, key.length - 6);
  panels[panelType] = context(key).default;
});

/**
 * Return the React Component definition for the specified panel type.
 * The result should be used with the React.createElement() method
 *
 * @export
 * @param {*} panelType - i.e. 'HeroPanel'
 * @returns - panel definition, to be used with React.createElement()
 */
export default function PanelFactory(panelType: string) {
  return panels[panelType];
}
