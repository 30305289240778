/* eslint-disable dot-notation */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PanelInfo } from '../_types/pageTypes';
import { RootState, useAppDispatch, useAppSelector } from '../app/store';
import { getOrder, getOrderList, UserState } from '../user/_userSlice';
import { getAllCategories, ProdState } from '../prod/_prodSlice';
import { OrdersPanelProps } from '../_types/ordersPanelTypes';
import OrderDetail from './Orders/OrderDetail';
import OrdersList from './Orders/OrdersList';
import { RequestStatus } from '../app/globals';
import toastIfError from '../common/utils/toastIfError';

interface OrdersPanelContent extends OrdersPanelProps {}

export default function OrdersPanel({ panel }: {
  panel: PanelInfo<OrdersPanelContent>
}) {
  const { pathname } = useLocation();

  const segments = pathname.split('/').filter((x) => x);
  const ordenId = !Number.isNaN(segments[segments.length - 1])
    ? parseInt(segments[segments.length - 1]) : 0;

  const { content } = panel;

  const {
    userInfo,
    prodInfo,
  } : {
    userInfo: UserState;
    prodInfo: ProdState;
  } = useAppSelector((state: RootState) => ({
    userInfo: state.user,
    prodInfo: state.prod,
  }));

  const dispatch = useAppDispatch();

  const { categTreeStatus } = prodInfo;

  useEffect(() => {
    if (categTreeStatus === RequestStatus.None) {
      dispatch(getAllCategories())
        .then(toastIfError('getAllCategories'));
    }

    if (ordenId > 0) {
      if (userInfo.ordenDetailStatus === RequestStatus.None || userInfo.ordenDetail.id !== ordenId) {
        dispatch(getOrder(ordenId))
          .then(toastIfError('getOrder'));
      }
    } else if (content.isQuote) {
      if (userInfo.presupuestosStatus === RequestStatus.None) {
        dispatch(getOrderList('P'))
          .then(toastIfError('getOrderList'));
      }
    } else if (userInfo.ordenesStatus === RequestStatus.None) {
      dispatch(getOrderList('O'))
        .then(toastIfError('getOrderList'));
    }
  }, [
    categTreeStatus,
    userInfo.ordenDetail.id,
    userInfo.ordenDetailStatus,
    userInfo.presupuestosStatus,
    userInfo.ordenesStatus,
    content.isQuote,
    ordenId,
    dispatch]);

  if (ordenId > 0) {
    return (
      <OrderDetail title={content.title} isQuote={content.isQuote} />
    );
  }

  return (
    <OrdersList title={content.title} isQuote={content.isQuote} segments={segments} />
  );
}
