/* eslint-disable no-bitwise */
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { apiUrl, RequestStatus } from '../app/globals';
// eslint-disable-next-line import/no-cycle
import { Product } from '../prod/_prodSlice';
import httpClient from '../common/utils/httpClient';
import { UserLogin, setAuthInfo, AppRoles, removeAuthData, checkTokenExpiration, getAuthInfo }
  from '../common/utils/authStorage';

/* ====================
  Types
  ====================== */

export interface OrderItem {
  idProd: number;
  prod: Product;
  qty: number;
  idOrdenDetalle?: number;
  idEstado: number; // only in orders
  nombreEstado: string; // only in orders
}

interface OrderHdr {
  id: number;
  tipo: string;
  nombre:string;
  fecha?: string;
  monto: number;
  descripcion:string;
  idEstado: number; // only in orders
  nombreEstado: string; // only in orders
}

export type Order = OrderHdr & {
  items: OrderItem[];
};

interface OrderList {
  tipo: string;
  ordenes: OrderHdr[];
}

export interface UserState {
  isAuthenticated: boolean,
  roles: AppRoles,
  email: string;
  nombre: string;
  apellido: string;
  isCartOpen: boolean;
  cart: Order;
  ordenes: OrderHdr[];
  presupuestos: OrderHdr[];
  ordenDetail: Order;
  userLoginStatus: RequestStatus;
  userDataStatus: RequestStatus;
  presupuestosStatus: RequestStatus;
  ordenesStatus: RequestStatus;
  ordenDetailStatus: RequestStatus;
  saveStatus: RequestStatus;
  error: SerializedError;
}

const emptyOrder = { id: 0,
  items: [],
  tipo: 'C',
  nombre: '',
  descripcion: '',
  monto: 0,
  idEstado: 1,
  nombreEstado: '' };

const emptyState: UserState = {
  isAuthenticated: false,
  roles: AppRoles.None,
  email: '',
  nombre: '',
  apellido: '',
  isCartOpen: false,
  cart: emptyOrder,
  ordenes: [],
  presupuestos: [],
  ordenDetail: emptyOrder,
  userLoginStatus: RequestStatus.None,
  userDataStatus: RequestStatus.None,
  presupuestosStatus: RequestStatus.Waiting,
  ordenesStatus: RequestStatus.None,
  ordenDetailStatus: RequestStatus.None,
  saveStatus: RequestStatus.None,
  error: {},
};

// verify if user already authenticated
const initialState = { ...emptyState };
const authInfo = getAuthInfo();
if (authInfo && checkTokenExpiration(authInfo)) {
  initialState.isAuthenticated = true;
  initialState.email = authInfo.username;
  initialState.roles = authInfo.roles;
}
/* ====================
  Thunk actions
  ====================== */

export const login = createAsyncThunk<
UserLogin, { username: string, password: string }, { state: { user: UserState | undefined } }>(
  'user/login',
  async ({ username, password }) => {

    const url = `${apiUrl}/auth/login`;
    const response = await httpClient(url, {}, 'POST', { username, password }) as { data: UserLogin };
    setAuthInfo(response.data);
    return response.data;
  },
);

export const getUserData = createAsyncThunk<UserState, undefined, { state: { user: UserState } }>(
  'user/getUserData',
  async () => {
    const url = `${apiUrl}/user`;
    const response = await httpClient(url);
    const userState = response.data.user as UserState;
    userState.cart = response.data.cart as Order;
    userState.presupuestos = response.data.quotes as OrderHdr[];
    return userState;
  },
);

// get orders of a certain type
export const getOrderList = createAsyncThunk<OrderList, string, { state: { user: UserState } }>(
  'user/getOrderList',
  async (tipo) => {
    const url = `${apiUrl}/user/orders?type=${tipo}`;
    const response = await httpClient(url);
    return { tipo, ordenes: response.data as OrderHdr[] };
  },
);

// get full order or quote
export const getOrder = createAsyncThunk<Order, number, { state: { user: UserState } }>(
  'user/getOrder',
  async (id) => {
    const url = `${apiUrl}/user/orders/${id}`;
    const response = await httpClient(url);
    return response.data as Order;
  },
);

// save cart, quote, order
export const addOrder = createAsyncThunk<Order, Order, { state: { user: UserState } }>(
  'user/addOrder',
  async (data, { getState }) => {
    const url = `${apiUrl}/user/orders`;
    const response = await httpClient(url, {}, 'POST', data);

    // save empty cart
    const cart = { ...getState().user.cart }; // clone cart (cannot change state here)
    cart.items = [];
    await httpClient(url, {}, 'PUT', cart);

    // return saved order
    return response.data;
  },
);

// add or edit item in current Cart
export const addItemToCart = createAsyncThunk<Order, { prod: Product, qty: any }, { state: { user: UserState } }>(
  'user/addItemToCart',
  async ({ prod, qty }, { getState }) => {

    const cart = { ...getState().user.cart }; // clone cart (cannot change state here)
    cart.items = [...cart.items]; // clone item array (cannot change state here)
    const index = cart.items.findIndex((x) => x.prod.id === prod.id);
    let qtyNum = parseInt(qty);
    if (Number.isNaN(qtyNum)) {
      qtyNum = 1;
    }

    if (index >= 0) {
      //  item exists
      const item = { ...cart.items[index] }; // clon item (cannot change state here
      item.qty += qtyNum;
      cart.items[index] = item;
    } else {
      //  item does not exist
      cart.items.push({ idProd: prod.id, prod, qty: qtyNum, idEstado: 1, nombreEstado: '' });
    }
    // save and return to reducer
    const url = `${apiUrl}/user/orders`;
    await httpClient(url, {}, 'PUT', cart);
    return cart;
  },
);

// remove cart item by index
export const removeCartItem = createAsyncThunk<Order, number, { state: { user: UserState } }>(
  'user/removeCartItem',
  async (index, { getState }) => {

    const cart = { ...getState().user.cart }; // clone cart (cannot change state here)
    if (index >= 0 && index < cart.items.length) {
      cart.items = [...cart.items]; // clone item array (cannot change state here)
      cart.items.splice(index, 1);
    }
    // save and return to reducer
    const url = `${apiUrl}/user/orders`;
    await httpClient(url, {}, 'PUT', cart);
    return cart;
  },
);

// copy quote items to carg
export const copyQuoteToCart = createAsyncThunk<Order, OrderItem[], { state: { user: UserState } }>(
  'user/copyQuoteToCart',
  async (items, { getState }) => {

    const cart = { ...getState().user.cart }; // clone cart (cannot change state here)
    cart.items = items;
    // save and return to reducer
    const url = `${apiUrl}/user/orders`;
    await httpClient(url, {}, 'PUT', cart);
    return cart;
  },
);

// delete quote
export const deleteQuote = createAsyncThunk<number, number, { state: { user: UserState } }>(
  'user/deleteQuote',
  async (id) => {
    const url = `${apiUrl}/user/orders/${id}`;
    const response = await httpClient(url, {}, 'DELETE');
    return response.data as number;
  },
);

/* ====================
  Slice definition
  ====================== */

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout() {
      removeAuthData();
      return initialState;
    },
    openCart(state) {
      state.isCartOpen = true;
    },
    closeCart(state) {
      state.isCartOpen = false;
    },
    forceNewLogin(state) {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    // LOGIN

    builder.addCase(login.pending,
      (state) => {
        state.userLoginStatus = RequestStatus.Waiting;
      });

    builder.addCase(login.fulfilled,
      (state, action) => {
        state.email = action.payload.email;
        state.roles = action.payload.roles;
        state.isAuthenticated = true;
        state.userLoginStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(login.rejected,
      (state, action) => {
        state.roles = AppRoles.None;
        state.isAuthenticated = false;
        state.userLoginStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    // USER DATA

    builder.addCase(getUserData.pending,
      (state) => {
        state.cart = emptyOrder;
        state.userDataStatus = RequestStatus.Waiting;
      });

    builder.addCase(getUserData.fulfilled,
      (state, action) => {
        state.isAuthenticated = true;
        state.nombre = action.payload.nombre;
        state.apellido = action.payload.apellido;
        state.email = action.payload.email;
        state.cart = action.payload.cart;
        state.presupuestos = action.payload.presupuestos;
        state.userDataStatus = RequestStatus.Ready;
        state.presupuestosStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getUserData.rejected,
      (state, action) => {
        if (action.error.code === '401') {
          removeAuthData();
          state.isAuthenticated = false;
        }
        state.userDataStatus = RequestStatus.Ready;
        state.presupuestosStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    // CREATE ORDER OR QUOTE

    builder.addCase(addOrder.fulfilled,
      (state, action) => {
        if (action.payload.tipo === 'P') {
          state.presupuestos.push(action.payload);
        } else {
          state.ordenes.push(action.payload);
        }
        state.isCartOpen = false;
        state.cart.items = [];
        state.error = {};
      });

    builder.addCase(addOrder.rejected,
      (state, action) => {
        state.error = action.error;
      });

    // GET ORDERS OR QUOTES

    builder.addCase(getOrderList.fulfilled,
      (state, action) => {
        if (action.payload.tipo === 'P') {
          state.presupuestos = action.payload.ordenes;
          state.presupuestosStatus = RequestStatus.Ready;
        } else if (action.payload.tipo === 'O') {
          state.ordenes = action.payload.ordenes;
          state.ordenesStatus = RequestStatus.Ready;
        }
        state.error = {};
      });
    builder.addCase(getOrderList.rejected,
      (state, action) => {
        if (action.meta.arg === 'P') {
          state.presupuestosStatus = RequestStatus.Ready;
        } else if (action.meta.arg === 'O') {
          state.ordenesStatus = RequestStatus.Ready;
        }
        state.error = action.error;
      });

    builder.addCase(getOrderList.pending,
      (state, action) => {
        if (action.meta.arg === 'P') {
          state.presupuestos = [];
          state.presupuestosStatus = RequestStatus.Waiting;
        } else if (action.meta.arg === 'O') {
          state.ordenes = [];
          state.ordenesStatus = RequestStatus.Waiting;
        }
      });

    // GET ORDER OR QUOTE DETAIL

    builder.addCase(getOrder.fulfilled,
      (state, action) => {
        state.ordenDetailStatus = RequestStatus.Ready;
        state.ordenDetail = action.payload;
        state.error = {};
      });

    builder.addCase(getOrder.rejected,
      (state, action) => {
        state.ordenDetailStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    builder.addCase(getOrder.pending,
      (state) => {
        state.ordenDetail = emptyOrder;
        state.ordenDetailStatus = RequestStatus.Waiting;
      });

    // DELETE QUOTE

    builder.addCase(deleteQuote.fulfilled,
      (state, action) => {
        const index = state.presupuestos.findIndex((x) => x.id === action.payload);
        if (index > 0) {
          state.presupuestos.splice(index, 1);
          state.presupuestosStatus = RequestStatus.Ready;
        }
        state.error = {};
      });

    builder.addCase(deleteQuote.rejected,
      (state, action) => {
        state.presupuestosStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    builder.addCase(deleteQuote.pending,
      (state) => {
        state.presupuestosStatus = RequestStatus.Waiting;
      });

    // ADD ITEM TO CART
    builder.addCase(addItemToCart.fulfilled,
      (state, action) => {
        state.saveStatus = RequestStatus.Ready;
        state.cart.items = action.payload.items;
        state.error = {};
      });

    builder.addCase(addItemToCart.rejected,
      (state, action) => {
        state.saveStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    builder.addCase(addItemToCart.pending,
      (state) => {
        state.saveStatus = RequestStatus.Waiting;
      });

    // REMOVE CART ITEM
    builder.addCase(removeCartItem.fulfilled,
      (state, action) => {
        state.saveStatus = RequestStatus.Ready;
        state.cart.items = action.payload.items;
        state.error = {};
      });

    builder.addCase(removeCartItem.rejected,
      (state, action) => {
        state.saveStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    builder.addCase(removeCartItem.pending,
      (state) => {
        state.saveStatus = RequestStatus.Waiting;
      });

    // COPY QUOTE TO CART
    builder.addCase(copyQuoteToCart.fulfilled,
      (state, action) => {
        state.saveStatus = RequestStatus.Ready;
        state.cart.items = action.payload.items;
        state.error = {};
      });

    builder.addCase(copyQuoteToCart.rejected,
      (state, action) => {
        state.saveStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    builder.addCase(copyQuoteToCart.pending,
      (state) => {
        state.saveStatus = RequestStatus.Waiting;
      });
  },
});

export const {
  logout,
  openCart,
  closeCart,
  forceNewLogin,
} = userSlice.actions;

export default userSlice;
