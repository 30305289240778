/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import contentSlice from './_contentSlice';
import prodSlice from '../prod/_prodSlice';
import userSlice from '../user/_userSlice';

const rootReducer = combineReducers({
  content: contentSlice.reducer,
  prod: prodSlice.reducer,
  user: userSlice.reducer,
});

export default rootReducer;
