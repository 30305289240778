/* eslint-disable dot-notation */
/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { SerializedError } from '@reduxjs/toolkit';
import { RootState, useAppDispatch, useAppSelector } from '../../app/store';
import { slugify, useQuery, bgImageStyle, formatNumber } from '../../common/utils/utils';
import { addItemToCart } from '../../user/_userSlice';
import { getProducts, Metadata, Product, Categ,
  ProdMetadatas, SelectFilter, SelectOption, SortFilter } from '../../prod/_prodSlice';
import 'react-input-range/lib/css/index.css';
import { Breadcrumb, BreadcrumbContent } from '../../common/components/Breadcrumb';
import ProductFilter from './ProductFilter';
import Icon from '../../common/components/Icon';
import { RequestStatus, lang } from '../../app/globals';
import { SiteInfo } from '../../_types/siteInfoTypes';
import './ProductList.scss';
import DefaultLoader from '../../common/loaders/DefaultLoader';
import EmptyBox from '../../common/loaders/EmptyBox';
import toastIfError from '../../common/utils/toastIfError';

export default function ProductList({ categ, bc, sortFilter }
:{ categ:Categ, bc:BreadcrumbContent, sortFilter:SortFilter }) {

  const [currentCateg, setCurrentCateg] = useState('');
  const [popUpFilters, openPopUpFilters] = useState(false);
  const [currentSort, setCurrentSort] = useState(sortFilter.options[0]);
  const [filterValues, setFiltersValues] = useState<null | object>(null);
  const { pathname } = useLocation();
  const fullQuery = useQuery();

  const codCateg: string = categ.cod;

  const handleSelectFilter = (event:any, filterName: string, isText?:boolean) => {

    if (filterValues) {
      const slug = slugify(filterName);
      const { value } = event.target;

      if (isText) {
        if (value.length === 0) {
          delete filterValues[slug];
        } else {
          filterValues[slug] = { value: `text_${slugify(value)}` };
        }
      } else if (filterValues[slug] !== undefined) {
        if (filterValues[slug].value === value) {
          delete filterValues[slug];
        } else if ((filterValues[slug].value as string).includes(value)
        && !(filterValues[slug].value as string).startsWith('text_')) {
          let newVal:string = (filterValues[slug].value as string);

          newVal = newVal.indexOf(value) > 0 && newVal[newVal.indexOf(value) - 1] !== '_'
            ? newVal.replace('_' + value, '')
            : newVal.replace(value, '');
          newVal = newVal.includes('__') ? newVal.replace('__', '_') : newVal;
          newVal = newVal.startsWith('_') ? newVal.slice(1, newVal.length) : newVal;
          newVal = newVal.endsWith('_') ? newVal.slice(0, newVal.length - 1) : newVal;

          filterValues[slug].value = newVal;
        } else if ((filterValues[slug].value as string).startsWith('text_')) {
          filterValues[slug].value = value;
        } else {
          filterValues[slug].value += '_' + value;
        }
      } else {
        filterValues[slug] = { value };
      }
      setFiltersValues(filterValues);
      updateUrlQuery();
    }
  };

  const queryStr = fullQuery.get('q');
  const query = queryStr ? `?q=${queryStr}` : '';

  const history = useHistory();

  const {
    siteInfo,
    products,
    productsStatus,
    metadatas,
    prodsFilters,
    error,
  } : {
    siteInfo: SiteInfo,
    products: Array<Product>;
    productsStatus: RequestStatus,
    metadatas: Metadata[],
    prodsFilters : SelectFilter[],
    error: SerializedError
  } = useAppSelector((state: RootState) => ({
    siteInfo: state.content.siteInfo,
    products: state.prod.products,
    productsStatus: state.prod.productsStatus,
    metadatas: state.prod.metadatas,
    prodsFilters: state.prod.prodsFilters,
    error: state.prod.error,
  }));

  const { terms } = siteInfo;

  const dispatch = useAppDispatch();
  const shouldFetch = productsStatus === RequestStatus.None || !currentCateg;

  useEffect(() => {
    if (shouldFetch) {
      dispatch(getProducts({ codCateg, query }))
        .then(toastIfError('getProducts'));
      setCurrentCateg(codCateg);
    }
  }, [query, shouldFetch]);

  useEffect(() => {
    if (!filterValues && prodsFilters.length) {
      // Load initial values
      const currentFilters = {};
      prodsFilters.forEach((f) => {
        const param = fullQuery.get(slugify(f.name));

        if (fullQuery && param) {
          currentFilters[slugify(f.name)] = { value: param };
        }
        setFiltersValues(currentFilters);
      });
    }
  }, [filterValues, prodsFilters, currentSort, codCateg]);

  const updateUrlQuery = () => {
    prodsFilters.forEach((f) => {
      if (filterValues && filterValues[slugify(f.name)]) {
        const { value } = filterValues[slugify(f.name)];
        if (value) {
          fullQuery.set(slugify(f.name), `${value}`);
        } else {
          fullQuery.delete(slugify(f.name));
        }
      } else {
        fullQuery.delete(slugify(f.name));
      }
    });
    history.push(pathname + (fullQuery ? '?' + fullQuery : ''));
  };

  const applyFilter = () : Product[] => {

    let prodsFiltered: Product[] = products;

    prodsFilters.forEach((f) => {
      const metadata = metadatas.find((m) => slugify(m.nombre) === slugify(f.name));

      switch (f.type) {
        case 'N':
          if (filterValues && filterValues[slugify(f.name)]?.value !== undefined) {
            const textValues: string[] = (filterValues[slugify(f.name)]?.value as string).split('_');

            if (textValues) {
              const numValues: number[] = [];

              textValues.forEach((v) => {
                const range: string[] = v.split('-');
                numValues.push(parseFloat(range[0].replace(',', '.')));
                numValues.push(parseFloat(range[1].replace(',', '.')));
              });
              numValues.sort((a, b) => a - b);

              let prodsRangeFiltered: Product[] = [];

              for (let i = 0; i < numValues.length; i += 1) {
                if (i === 0 || !!(i && !(i % 2))) {
                  const min = numValues[i];
                  const max = numValues[i + 1];

                  if (metadata) {
                    prodsRangeFiltered = prodsRangeFiltered.concat(prodsFiltered
                      .filter((p) => (min <= (p['M' + metadata?.id] as ProdMetadatas)?.numericValue ?? 0)
                      && ((p['M' + metadata?.id] as ProdMetadatas)?.numericValue ?? 0) <= max));
                  } else {
                    prodsRangeFiltered = prodsRangeFiltered.concat(prodsFiltered
                      .filter((p) => (min <= (p[slugify(f.name)] as number))
                      && (p[slugify(f.name)] as number) <= max));
                  }
                }
              }
              prodsFiltered = prodsRangeFiltered;
            }
          }
          break;
        case 'T':

          if (filterValues && filterValues[slugify(f.name)]?.value !== undefined) {
            const textValues: string[] = (filterValues[slugify(f.name)]?.value as string).split('_');

            if (textValues) {
              textValues.forEach((text:string) => slugify(text));

              if (textValues[0] === 'text') {
                prodsFiltered = prodsFiltered.filter((p) => (
                  slugify(p.marca).includes(textValues[1])));
              } else if (metadata) {
                prodsFiltered = prodsFiltered.filter((p) => (
                  textValues.includes(slugify((p['M' + metadata?.id] as ProdMetadatas)?.textValue ?? ''))));
              } else {
                prodsFiltered = prodsFiltered.filter((p) => (
                  textValues.includes(slugify(p[slugify(f.name)]))));
              }
            }
          }
          break;
        default:
          break;
      }

    });

    // set current sort
    if (prodsFiltered.length > 0) {
      switch ((currentSort as SelectOption).id) {
        case 1:
          prodsFiltered = prodsFiltered.slice().sort((a, b) => a.precio - b.precio);
          break;
        case 2:
          prodsFiltered = prodsFiltered.slice().sort((a, b) => b.precio - a.precio);
          break;
        case 3:
          prodsFiltered = prodsFiltered.slice().sort((a, b) => a.plazoEntrega - b.plazoEntrega);
          break;
        case 4:
          prodsFiltered = prodsFiltered.slice().sort((a, b) => {
            if (a.marca < b.marca) { return -1; }
            if (a.marca > b.marca) { return 1; }
            return 0;
          });
          break;
        case 5:
          prodsFiltered = prodsFiltered.slice().sort((a, b) => {
            if (a.marca > b.marca) { return -1; }
            if (a.marca < b.marca) { return 1; }
            return 0;
          });
          break;
        default:
          break;
      }
    }

    return prodsFiltered;
  };

  return (
    <section className="ProductList" id="catalog">
      <Breadcrumb urls={bc.urls} names={bc.names} />

      <div className="d-flex flex-column align-items-center w-100">

        <div className="container list">
          <div className="list__heading flex-column">
            <div className="list__title title-01">{categ.nombre}</div>
            <p className="list__results body-light-01">{
              terms['results']
                .replace('{{cantProd}}', categ.cantProd.toString())
            }
            </p>
            {categ.descripcion && (
            <p className="list__description body-light-01">
              {categ.descripcion ? categ.descripcion : 'description...'}
            </p>
            )}
          </div>
          {error.message ? <EmptyBox /> : !products?.length ? <DefaultLoader /> : (
            <div className="d-flex">
              <div className={`list__filters ${popUpFilters ? 'pop-up--open' : ''}`}>
                <div className="pop-up-heading">
                  <button type="button" className="btn body-medium-02"
                    onClick={() => openPopUpFilters(!popUpFilters)}
                  >
                    <Icon type="close--red" width={20} height={20} /><span>{terms['filter']}</span>
                  </button>
                </div>
                { filterValues
                && prodsFilters.map((filter) => (
                  <ProductFilter filter={filter}
                    handleSelectFilter={handleSelectFilter}
                    filterValues={filterValues ?? null}
                    key={slugify(filter.name)}
                    />
                ))}
              </div>

              <div className="list__items">
                <div className="list__sort d-flex justify-content-end">
                  {filterValues && (
                  <button className="list__mb-filters-button btn mr-auto body-medium-01" type="button"
                    onClick={() => openPopUpFilters(!popUpFilters)}
                  >
                    {terms['filter']}
                  </button>
                  )}

                  {sortFilter && (
                  <>
                    <span className="list__sort-copy body-light-01">{terms['sortBy']}</span>
                    <Dropdown className="list__sort-dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {(currentSort as SelectOption).label}
                        <div className="dropdown-icon">
                          <Icon type="arrow-down" width={16} height={16} />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {sortFilter.options.map((opt: any) => (
                          <Dropdown.Item key={opt.id} name={opt.key}
                            id={opt.id} onClick={() => setCurrentSort(opt)}
                            className="link column__link"
                          >
                            {opt.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                  )}
                </div>
                {applyFilter().map((prod) => {
                  const idDigitsCount = Number.parseInt(prod.id.toString().substring(1)).toString().length;
                  const imageFolder = idDigitsCount > 2
                    ? `2${'0'.repeat(4 + idDigitsCount - 3)}`
                    + `${prod.id.toString().slice(-idDigitsCount)[idDigitsCount - 3]}00`
                    : '20000000';

                  return (
                    <div className="item d-flex" key={prod.id}>
                      <div className="item__image--mobile" style={
                    bgImageStyle(`/dataImages/productos/${imageFolder}/${prod.id}.jpg`)
                    } />
                      <div className="d-flex w-100">
                        <div className="item__image--desktop" style={
                    bgImageStyle(`/dataImages/productos/${imageFolder}/${prod.id}.jpg`)
                    } />
                        <div className="item__mid">
                          <div className="item__tagline tagline-01">{prod.marca}</div>
                          <Link to={`/${lang}/catalog/${codCateg}~${slugify(categ.nombre)}/${prod.id}`}>
                            <div className="item__title body-light-02">{prod.nombre}</div>
                          </Link>
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{ __html: prod.idsRelacionales }}
                            className="item__ids body-light-01" />
                        </div>
                        <div className="item__end">
                          <div className="item__price">
                            <span className="item__currency">{prod.divisa} </span>
                            {formatNumber(prod.precio, lang, true)}
                            <span className="item__measure body-reg-01"> / {prod.unidadMedida}</span>
                          </div>
                          <div className="item__delivery body-light-01">{
                          terms['deliveryTime']
                            .replace('{{plazoEntrega}}', prod.plazoEntrega.toString())
                      }
                          </div>
                          <div className="item__quantity d-flex">
                            <div className="quantity__label body-medium-01">{terms['quantity']}:
                            </div>
                            <div className="flex-grow-1">
                              <input type="number" id={`${prod.id}-qty`} name="quantity"
                                min="1" max="9999" defaultValue="1" />
                            </div>
                          </div>
                          <button className="ml-auto btn btn-dark body-medium-02" type="button"
                            onClick={() => dispatch(
                              addItemToCart({
                                prod,
                                qty: (document.getElementById(`${prod.id}-qty`) as HTMLInputElement).value }),
                            )}
                          >
                            {terms['addToCart']}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
