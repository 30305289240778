import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { RootState, useAppSelector } from '../app/store';
import { bgImageStyle, slugify } from '../common/utils/utils';
import { PanelInfo } from '../_types/pageTypes';
import { Categ } from '../prod/_prodSlice';
import { lang } from '../app/globals';
import './CardGalleryPanel.scss';
import { CardGalleryPanelProps } from '../_types/cardGalleryPanelTypes';
import DefaultLoader from '../common/loaders/DefaultLoader';

interface CardGalleryPanelContent extends CardGalleryPanelProps {}

export default function CardGalleryPanel({ panel }: {
  panel: PanelInfo<CardGalleryPanelContent>
}) {
  const { content } = panel;
  const {
    featuredCategs,
  } : {
    featuredCategs: Array<Categ>;
  } = useAppSelector((state: RootState) => ({
    featuredCategs: state.prod.featuredCategs,
  }));

  return (
    <section className="CardGalleryPanel" id="catalog">
      <div className="container">
        <div className="panel__heading d-flex flex-row">
          <div className="panel__title flex-grow-1 title-01">{content.title}</div>
          <div className="panel__button">
            <Link to={`/${lang}/catalog`} className="body-reg-01">{content.buttonCopy}</Link>
          </div>
        </div>
        <div className={`panel__cards d-flex 
          ${featuredCategs.length < 3 || (featuredCategs.length % 3 === 0 && featuredCategs.length % 4 !== 0)
          ? 'three-per-row' : 'four-per-row'}`}
        >
          {!featuredCategs.length ? <DefaultLoader count={6} />
            : featuredCategs.map((item) => {
              const idDigitsCount = item.id.toString().length;
              return (
                <div className="card" key={item.id}>
                  <Link to={`/${lang}/catalog/${item.cod}~${slugify(item.nombre)}`}>
                    <div className="card__title">{item.nombre}</div>
                    <div
                      className="card__image"
                      style={bgImageStyle(item.imagen ? '/dataImages/' + item.imagen
                        : '/dataImages/categorias/CA' + '0'.repeat(5 - idDigitsCount) + item.id.toString() + '.jpg')}
                />
                  </Link>
                </div>
              );
            })}
        </div>
        <div className="panel__bottom-bar flex-row">
          <div className="panel__button">
            <Link to={`/${lang}/catalog`} className="body-reg-01">{content.buttonCopy}</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
