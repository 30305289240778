/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { bgImageStyle, mediaUrl } from '../common/utils/utils';
import { login, UserState } from '../user/_userSlice';
import { RootState, useAppDispatch, useAppSelector } from '../app/store';
import { RequestStatus } from '../app/globals';
import { LoginContent } from '../_types/loginDialogTypes';
import toastIfError from '../common/utils/toastIfError';
import './LoginDialog.scss';

export default function LoginDialog({ content }: { content:LoginContent }) {
  const {
    userInfo,
  } : {
    userInfo: UserState;
  } = useAppSelector((state: RootState) => ({
    userInfo: state.user,
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useAppDispatch();

  const onSubmit = async (values:any) => {
    await dispatch(login({ username: values.username, password: values.password }))
      .then(toastIfError('login', {
        customMessages: { 401: content.credentialsError },
      }));
  };

  return (
    <section className="LoginDialog panel">
      <div className="panel__background-wrapper"
        style={bgImageStyle(content.background)}
      >
        <div className="panel__form-container">
          <img src={mediaUrl(content.logo)} alt="logo" className="form__logo" />
          <form onSubmit={handleSubmit(onSubmit)} className="panel__form">
            <input type="email" {...register('username')} className="form__input"
              placeholder={content.userPlaceholder} />
            <input type="password" {...register('password')} className="form__input"
              placeholder={content.passwordPlaceholder} />
            {userInfo.userLoginStatus !== RequestStatus.Waiting && (
            <button type="submit" className="form__btn-submit body-reg-01">{content.submitCopy}</button>
            )}
          </form>
          {userInfo.userLoginStatus !== RequestStatus.Waiting && (
          <>
            <span className="form__register-copy body-light-02">{content.registerCopy}</span>
            <Link className="form__register-btn body-light-02" to={content.registerLinkUrl}>
              {content.registerLinkCopy}
            </Link>
          </>
          )}
        </div>
      </div>

      <div className="panel__text-container">
        <div className="container">
          <div className="panel__title">{content.title}</div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="panel__body" dangerouslySetInnerHTML={{ __html: content.body }} />
        </div>
      </div>
    </section>

  );
}
