import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import { bgImageStyle } from '../common/utils/utils';
import './BannerSliderPanel.scss';
import { PanelInfo } from '../_types/pageTypes';
import { ImageInfo } from '../_types/commonTypes';
import { mediaUrl } from '../common/utils/utils';

interface BannerSliderPanelContent {
  items: Array<ImageInfo>;
}

export default function BannerSliderPanel({ panel }: {
  panel: PanelInfo<BannerSliderPanelContent>
}) {
  const { content } = panel;

  return (
    <section className="BannerSliderPanel">
      <Carousel controls={false} touch>
        {content.items && (
          content.items.map((item: ImageInfo) => (

            <Carousel.Item key={item.imageAlt}>
              <img
                className="carousel-image"
                src={mediaUrl(item.imageSource)}
                alt={item.imageAlt}
      />
            </Carousel.Item>
          ))
        )}
      </Carousel>
    </section>
  );
}
