import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import axios from 'axios';
import { SiteInfo } from '../_types/siteInfoTypes';
import { lang, apiUrl, RequestStatus } from './globals';

export interface ContentState {
  siteInfo: SiteInfo;
  isLoaded: boolean;
  pages: any;
  getContentStatus: RequestStatus;
  error: SerializedError;
}
interface GetContentResponse {
  content: ContentState;
}

const initialState = {
  siteInfo: { header: {}, footer: {}, contact: {} },
  isLoaded: false,
  pages: [],
  getContentStatus: RequestStatus.None,
  error: {},
} as ContentState;

export const getContent = createAsyncThunk<GetContentResponse, undefined>(
  'content/getContent',
  async () => {
    const url = `${apiUrl}/content/${lang}`;
    const response = await axios(url);
    return response.data as GetContentResponse;
  },
);

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // LOGIN

    builder.addCase(getContent.pending,
      (state) => {
        state.getContentStatus = RequestStatus.Waiting;
      });

    builder.addCase(getContent.fulfilled,
      (state, action) => {
        state.siteInfo = action.payload.content.siteInfo;
        state.pages = action.payload.content.pages;
        state.isLoaded = true;
        state.getContentStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getContent.rejected,
      (state, action) => {
        state.siteInfo = initialState.siteInfo;
        state.isLoaded = false;
        state.getContentStatus = RequestStatus.Ready;
        state.error = action.error;
      });
  },
});

export default contentSlice;
