/* eslint-disable dot-notation */
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl, RequestStatus } from '../app/globals';
import httpClient from '../common/utils/httpClient';
// eslint-disable-next-line import/no-cycle
import { buildCategTree, buildProdsFilters } from './prodUtils';

export interface SelectFilter{
  type: string,
  name: string,
  hasTextInput: boolean,
  options: Array<SelectOption>,
}
export interface SelectOption {
  id: number,
  key: string,
  label: string,
  value: boolean,
}
export interface SortFilter {
  options: SortOption[],
}
export interface SortOption {
  id: number,
  key: string,
  label: string,
  value: boolean,
}

export type ProdState = {
  categDict: Dictionary<Categ>,
  categTree: CategNode,
  categTreeStatus: RequestStatus,
  searchQuery: string,
  searchTree: CategNode,
  searchTreeStatus: RequestStatus,
  featuredCategs: Categ[],
  featuredCategsStatus: RequestStatus,
  products: Product[],
  productsStatus: RequestStatus,
  metadatas: Metadata[],
  prodMetadatas:ProdMetadatas[],
  prodsFilters : SelectFilter[],
  currentProdDetail: ProdDetail,
  currentProdDetailStatus: RequestStatus,
  error: SerializedError;
};
export interface Metadata {
  id: number,
  codCat: string,
  nombre: string,
  tipo: string,
  max: number,
  min: number
}

export interface Product {
  id: number,
  codCat: string,
  nombre: string,
  imagen?: string,
  prodMetadatas: ProdMetadatas[],
  // new props
  marca: string,
  precio: number,
  divisa: string,
  unidadMedida: string,
  plazoEntrega: number,
  idsRelacionales: string,
  // new props end
}

export type ProdDetail = Product & {
  imagenes: DetailImage[],
  descripcion: DetailItem[],
  especificaciones: DetailItem[],
};

export interface DetailItem {
  label: string,
  value: string,
}

export interface DetailImage {
  original: string,
  thumbnail: string,
}

export interface ProdResult {
  prods: Product[],
  metadatas: Metadata[],
  prodMetadatas: ProdMetadatas[],
}

export interface ProdMetadatas {
  idProd: number,
  idMetadata: number,
  textValue: string,
  numericValue: number,
}

export type CategBase = {
  cod: string,
  cantProd : number,
};

export type CategNode = CategBase & {
  children: Dictionary<CategNode>,
};

export type Categ = CategBase & {
  id: number,
  nombre:string,
  imagen:string,
  descripcion:string,
};

export interface Dictionary<T> {
  [key: string]: T;
}

const initialState: ProdState = {
  featuredCategs: [],
  categDict: {},
  searchQuery: '',
  categTree: { cod: '', children: {}, cantProd: 0 },
  searchTree: { cod: '', children: {}, cantProd: 0 },
  categTreeStatus: RequestStatus.None,
  searchTreeStatus: RequestStatus.None,
  featuredCategsStatus: RequestStatus.None,
  products: [],
  productsStatus: RequestStatus.None,
  metadatas: [],
  prodMetadatas: [],
  prodsFilters: [],
  currentProdDetail: {
    id: 0,
    codCat: '',
    nombre: '',
    imagen: '',
    prodMetadatas: [],
    marca: '',
    precio: 0,
    divisa: '',
    unidadMedida: '',
    plazoEntrega: 0,
    idsRelacionales: '',
    imagenes: [],
    descripcion: [],
    especificaciones: [],
  },
  currentProdDetailStatus: RequestStatus.None,
  error: {},
};

export const getSearchCategories = createAsyncThunk<{ categs:CategBase[], query:string }, string>(
  'prod/getSearchCategories',
  async (search) => {
    const url = `${apiUrl}/categ/search?q=${search}`;
    const response = await httpClient(url);
    return { categs: response.data as CategBase[], query: search };
  },
);

export const getAllCategories = createAsyncThunk<Categ[], undefined>(
  'prod/getAllCategories',
  async () => {
    const url = `${apiUrl}/categ`;
    const response = await httpClient(url);
    return response.data as Categ[];
  },
);

export const getFeaturedCategories = createAsyncThunk<Categ[], undefined>(
  'prod/getFeaturedCategories',
  async () => {
    const url = `${apiUrl}/categ/featured`;
    const response = await axios.get(url);
    return response.data as Categ[];
  },
);
export const getProducts = createAsyncThunk<ProdResult, { codCateg:string, query:string }>(
  'prod/getProducts',
  async ({ codCateg, query }) => {
    const url = `${apiUrl}/prod/${codCateg}${query}`;
    const response = await httpClient(url);
    return response.data as ProdResult;
  },
);
export const getProductDetail = createAsyncThunk<ProdDetail, number>(
  'prod/getProductDetail',
  async (id) => {
    const url = `${apiUrl}/prod/${id}/detail`;
    const response = await httpClient(url);
    return response.data as ProdDetail;
  },
);

const prodSlice = createSlice({
  name: 'prod',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    // getSearchCategories
    builder.addCase(getSearchCategories.pending,
      (state) => {
        state.searchTreeStatus = RequestStatus.Waiting;
      });

    builder.addCase(getSearchCategories.fulfilled,
      (state, action) => {
        const newTree = { cod: '', children: {}, cantProd: 0 };
        if (action.payload.categs.length > 0) {
          buildCategTree(action.payload.categs, 0, newTree, '', 1);
        }
        state.searchTree = newTree;
        state.searchQuery = action.payload.query;
        state.searchTreeStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getSearchCategories.rejected,
      (state, action) => {
        state.searchTreeStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    // getFeaturedCategories
    builder.addCase(getFeaturedCategories.pending,
      (state) => {
        state.featuredCategsStatus = RequestStatus.Waiting;
      });

    builder.addCase(getFeaturedCategories.fulfilled,
      (state, action) => {
        state.featuredCategs = action.payload;
        state.featuredCategsStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getFeaturedCategories.rejected,
      (state, action) => {
        state.featuredCategsStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    // getAllCategories
    builder.addCase(getAllCategories.pending,
      (state) => {
        state.categTreeStatus = RequestStatus.Waiting;
      });

    builder.addCase(getAllCategories.fulfilled,
      (state, action) => {
        const dic = {};
        action.payload.forEach((item:Categ) => {
          dic[item.cod] = item;
        });
        state.categDict = dic;

        const newTree = { cod: '', children: {}, cantProd: 0 };
        buildCategTree(action.payload, 0, newTree, '', 1);

        state.categTree = newTree;
        state.categTreeStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getAllCategories.rejected,
      (state, action) => {
        state.categTreeStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    // getProducts
    builder.addCase(getProducts.pending,
      (state) => {
        state.productsStatus = RequestStatus.Waiting;
        state.products = [];
        state.metadatas = [];
        state.prodMetadatas = [];
      });

    builder.addCase(getProducts.fulfilled,
      (state, action) => {
        state.products = action.payload.prods;
        state.metadatas = action.payload.metadatas;
        state.prodMetadatas = action.payload.prodMetadatas;

        state.products.forEach((prod) => {
          const metadatas = state.prodMetadatas.filter((f) => f.idProd === prod.id);

          metadatas.forEach((m) => {
            prod['M' + m.idMetadata] = m;
          });
        });

        state.prodsFilters = buildProdsFilters(state.products, state.metadatas, state.prodMetadatas);
        state.productsStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getProducts.rejected,
      (state, action) => {
        state.productsStatus = RequestStatus.Ready;
        state.error = action.error;
      });

    // getProductDetail
    builder.addCase(getProductDetail.pending,
      (state) => {
        state.currentProdDetailStatus = RequestStatus.Waiting;
      });

    builder.addCase(getProductDetail.fulfilled,
      (state, action) => {
        state.currentProdDetail = action.payload;
        state.currentProdDetailStatus = RequestStatus.Ready;
        state.error = {};
      });

    builder.addCase(getProductDetail.rejected,
      (state, action) => {
        state.currentProdDetailStatus = RequestStatus.Ready;
        state.error = action.error;
      });
  },
});

// export const {

// } = prodSlice.actions;

export default prodSlice;
