/* eslint-disable dot-notation */
/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import toast from 'react-hot-toast';
import { RootState, useAppDispatch, useAppSelector } from '../../app/store';
import { addItemToCart } from '../../user/_userSlice';
import { ProdDetail, getProductDetail } from '../../prod/_prodSlice';
import { Breadcrumb, BreadcrumbContent } from '../../common/components/Breadcrumb';
import { SiteInfo } from '../../_types/siteInfoTypes';
import './ProductDetail.scss';
import { formatNumber } from '../../common/utils/utils';
import { RequestStatus, lang } from '../../app/globals';
import EmptyBox from '../../common/loaders/EmptyBox';
import toastIfError from '../../common/utils/toastIfError';

export default function ProductDetail({ prodId, bc }:{ prodId: number, bc: BreadcrumbContent }) {
  const [thumbPosition, setThumbPosition] = useState('bottom');
  const dispatch = useAppDispatch();

  const handleResize = () => {
    if (window.innerWidth < 992) {
      setThumbPosition('left');
    } else {
      setThumbPosition('bottom');
    }
  };

  const {
    siteInfo,
    detail,
    detailStatus,
  } : {
    siteInfo: SiteInfo,
    detail: ProdDetail,
    detailStatus: RequestStatus.None,
  } = useAppSelector((state: RootState) => ({
    siteInfo: state.content.siteInfo,
    detail: state.prod.currentProdDetail,
    detailStatus: state.prod.currentProdDetailStatus,
  }));

  const { terms } = siteInfo;

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    if (detailStatus === RequestStatus.None) {
      dispatch(getProductDetail(prodId))
        .then((response: any) => {
          if (response.error && response.error.code !== '401') {
            toast.error(`${terms['unknownError']}: ${response.error.message} (getProductDetail / ${response.error.code})`);
          }
        });
    }
  }, [window, prodId, detailStatus, detail, dispatch]);

  return (
    <section className="ProductDetail" id="detail">
      <Breadcrumb urls={bc.urls} names={bc.names} />

      <div className="d-flex flex-column align-items-center w-100">

        <div className="container detail">
          {detail?.id !== prodId ? (
            <EmptyBox />
          ) : (
            <>
              <div className="detail__heading flex-column">
                <div className="detail__title title-01">{detail.nombre}</div>
              </div>
              <div className="detail__item item">
                <div className="d-flex w-100" key={detail.id}>
                  <div className="item__top-data--mobile">
                    <div className="item__tagline tagline-01">{detail.marca}</div>
                    <div className="item__title">{detail.nombre}</div>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: detail.idsRelacionales }}
                      className="item__ids body-light-01" />
                  </div>
                  <div className="item__image">
                    {detail.imagenes && thumbPosition && (
                    <ImageGallery items={detail.imagenes}
                      showThumbnails={detail.imagenes.length > 1}
                      thumbnailPosition={thumbPosition as 'top' | 'right' | 'bottom' | 'left' | undefined}
                      showPlayButton={false}
                      showFullscreenButton={false}
                    />
                    )}
                  </div>
                  <div className="item__main-data">
                    <div className="item__tagline tagline-01">{detail.marca}</div>
                    <div className="item__title">{detail.nombre}</div>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: detail.idsRelacionales }}
                      className="item__ids body-light-01" />
                    <div className="item__price">
                      <span className="item__currency">{detail.divisa} </span>
                      {formatNumber(detail.precio, lang, true)}
                      <span className="item__measure body-reg-01"> / {detail.unidadMedida}</span>
                    </div>
                    <div className="item__delivery body-light-01">
                      {terms['deliveryTime']
                        .replace('{{plazoEntrega}}', `${detail.plazoEntrega.toString()}`)}
                    </div>
                    <div className="item__quantity d-flex">
                      <div className="quantity__label body-medium-01">{terms['quantity']}:</div>
                      <div>
                        <input type="number" id={`${detail.id}-qty`} name="quantity" min="1" max="9999" defaultValue="1" />
                      </div>
                      <button className="cart-button--mobile btn btn-dark body-medium-02" type="button"
                        onClick={() => dispatch(
                          addItemToCart({
                            prod: detail,
                            qty: (document.getElementById(`${detail.id}-qty`) as HTMLInputElement).value }),
                        ).then(toastIfError('addItemToCart'))}
                      >
                        {terms['addToCart']}
                      </button>
                    </div>
                    <button className="cart-button--desktop mr-auto btn btn-dark body-medium-02" type="button"
                      onClick={() => dispatch(
                        addItemToCart({
                          prod: detail,
                          qty: (document.getElementById(`${detail.id}-qty`) as HTMLInputElement).value }),
                      )
                        .then(toastIfError('addItemToCart'))}
                    >
                      {terms['addToCart']}
                    </button>
                  </div>
                </div>
                {detail.descripcion && (
                <div className="item__description">
                  <div className="item__description-title detail-title">{terms['description']}</div>
                  {detail.descripcion.map((item) => (
                    <div className="item__description-value" key={item.label}>
                      {item.label ? item.label + ': ' : ''}{item.value}
                    </div>
                  ))}
                </div>
                )}
                {detail.especificaciones && (
                <div className="item__specs">
                  <div className="item__specs-title detail-title">{terms['technicalSpecs']}</div>
                  <div className="item__specs-table">
                    <table>
                      {detail.especificaciones.map((item) => (
                        <tbody key={item.label}>
                          <tr>
                            <td>{item.label}</td>
                            <td>{item.value}</td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
