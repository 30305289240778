/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import preval from 'preval.macro';
import store from './app/store';
import './index.scss';
import { rootElement } from './app/globals';

// wrap in a function so it can also be called on module hot replacement
const render = () => {
  const App = require('./app/App').default;

  ReactDOM.render(
    <Provider store={store}>
      <span hidden data-built={preval`module.exports = new Date().toString();`} />
      <App />
    </Provider>,
    rootElement,
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
