/* eslint-disable dot-notation */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { RootState, useAppSelector, useAppDispatch } from '../../app/store';
import { closeCart, deleteQuote, copyQuoteToCart, getOrder, Order,
  addOrder, UserState } from '../../user/_userSlice';
import { Categ, Dictionary, ProdState } from '../../prod/_prodSlice';
import { SiteInfo } from '../../_types/siteInfoTypes';
import { bgImageStyle, formatDateTime, formatNumber, slugify } from '../../common/utils/utils';
import { lang } from '../../app/globals';
import ConfirmModal from '../../common/components/ConfirmModal';
import Icon from '../../common/components/Icon';
import DefaultLoader from '../../common/loaders/DefaultLoader';
import toastIfError from '../../common/utils/toastIfError';
import OrdersPrice from './OrderPrice';
import './OrderDetail.scss';

export default function OrderDetail({ title, isQuote }:{ title:string, isQuote:boolean }) {

  const {
    userInfo,
    prodInfo,
    siteInfo,
  } : {
    userInfo: UserState;
    prodInfo: ProdState;
    siteInfo: SiteInfo;
  } = useAppSelector((state: RootState) => ({
    userInfo: state.user,
    prodInfo: state.prod,
    siteInfo: state.content.siteInfo,
  }));

  const { ordenDetail, cart } = userInfo;
  const { categDict } = prodInfo;
  const { terms } = siteInfo;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [onEdit, setOnEdit] = useState(false);

  useEffect(() => {
    if (cart.id && cart.id === ordenDetail.id) {
      setOnEdit(true);
    } else {
      if (onEdit) {
        dispatch(getOrder(ordenDetail.id))
          .then(toastIfError('getOrder'));
      }
      setOnEdit(false);
    }
  }, [cart.id, ordenDetail.id, onEdit]);

  // Modal state variables
  const [showModal, setShowModal] = useState(false);
  const [buyOrder, setBuyOrder] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [deleteQuoteId, setDeleteQuoteId] = useState(-1);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState({});

  // Modal bodies
  const modalDeleteQuoteBody = (
    <p>{terms['sureToDelete']}
      <strong className="quote-id"> #{ordenDetail.id}</strong> <strong>{ordenDetail.nombre}</strong>?
    </p>
  );
  const modalConfirmCopyToCart = (<p>{terms['confirmCopyToCart']}</p>);
  const modalConfirmBuyBody = (
    <div>
      <div>{terms['name']}</div>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder={`${terms['name']}...`}
          id="order-name" defaultValue={ordenDetail.nombre && ordenDetail.nombre} />
      </div>
      <div>{terms['description']}</div>
      <div className="input-group">
        <textarea className="form-control" placeholder={`${terms['description']}...`}
          id="order-description" defaultValue={ordenDetail.descripcion && ordenDetail.descripcion} />
      </div>
    </div>
  );
  const modalConfirmBuySuccessBody = (<p>{terms['orderSaved']}</p>);
  const modalConfirmBuyFailureBody = (<p>{terms['orderNotSaved']}</p>);

  const initDeleteQuoteModal = () => {
    setDeleteQuoteId(ordenDetail.id);
    setModalTitle(terms['deleteQuote']);
    setModalBody(modalDeleteQuoteBody);
    setShowModal(true);
  };
  const initCopyToCartModal = () => {
    setModalTitle(terms['copyToCartButton']);
    setModalBody(modalConfirmCopyToCart);
    setShowModal(true);
  };
  const initConfirmBuyModal = () => {
    setBuyOrder(true);
    setModalBody(modalConfirmBuyBody);
    setModalTitle(terms['saveOrderTitle']);
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    if (deleteQuoteId > 0) {
      // delete quote

      dispatch(deleteQuote(deleteQuoteId))
        .then(toastIfError('deleteQuote'));
      history.goBack();

    } else if (buyOrder) {
      // save or edit orders
      const nombre = (document.getElementById('order-name') as HTMLInputElement)?.value;
      const descripcion = (document.getElementById('order-description') as HTMLInputElement)?.value;

      if (nombre !== undefined && nombre.length > 0) {
        // save buy order

        const newOrder: Order = {
          id: ordenDetail.id,
          items: ordenDetail.items,
          nombre,
          descripcion,
          monto: ordenDetail.monto,
          tipo: 'O',
          idEstado: 1,
          nombreEstado: '',
        };
        dispatch(addOrder(newOrder))
          .then(toastIfError('addOrder'));
        setModalBody(modalConfirmBuySuccessBody);
        setRefreshModal(true);
      } else {
        setModalBody(modalConfirmBuyFailureBody);
        setRefreshModal(true);
      }
    } else {
      // copy quote to cart

      dispatch(copyQuoteToCart(ordenDetail.items))
        .then(toastIfError('copyQuoteToCart'));
      history.push('/' + lang + '/');
    }
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setDeleteQuoteId(-1);
    setBuyOrder(false);
    setShowModal(false);
    setRefreshModal(false);
  };
  if (!ordenDetail.id) {
    return <DefaultLoader />;
  }
  return (
    <section className={`${isQuote ? 'QuoteDetail' : 'OrderDetail'}`} id="detail">
      <ConfirmModal
        onClickConfirm={() => handleModalConfirm()}
        onClickCancel={() => handleModalCancel()}
        title={modalTitle}
        body={modalBody}
        show={showModal}
        hasContinue={refreshModal}
      />
      <div className="d-flex flex-column align-items-center w-100">
        <div className="container list">
          <div className="list__heading--mobile">
            <div className="list__detail--mobile">
              <div className="list__detail-id--label body-reg-01">
                {terms[isQuote ? 'quoteNumber' : 'orderNumber']}
              </div>
              <div className="list__detail-id">
                <span className="title-01">{ordenDetail.id}</span>
                <span className="body-reg-01">
                  {!isQuote && ordenDetail.fecha && ' ' + formatDateTime(ordenDetail.fecha)}
                </span>
              </div>
              {!isQuote && (
              <div>
                <div className="list__detail-name--label body-reg-01">
                  {terms['name']}
                </div>
                <div className="list__detail-name tagline-02">
                  {ordenDetail.nombre}
                </div>
              </div>
              )}
              <div className="d-flex">
                <div className="list__detail-bottom">
                  {isQuote ? (
                    <>
                      <div className="list__detail-name--label body-reg-01">
                        {terms['name']}
                      </div>
                      <div className="list__detail-name tagline-02">
                        {ordenDetail.nombre}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="list__detail-state--label tagline-01">
                        {terms['state']}
                      </div>
                      <div className="list__detail-state tagline-02">
                        {ordenDetail.nombreEstado}
                      </div>
                    </>
                  )}
                </div>
                <div className="list__detail-bottom">
                  <button className="list__consult-order btn btn-secondary"
                    type="button"
                  >
                    <span>{terms[isQuote ? 'consultQuote' : 'consultOrder']}</span>
                    <Icon type="mail" width={16} height={16} className="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="list__heading flex-column">
            <h1 className="list__title title-01">{title}</h1>
          </div>
          <div className="d-flex list__content">
            <div className="list__items">
              {ordenDetail.items
              && ordenDetail.items.map((item) => {
                const idDigitsCount = Number.parseInt(item.prod.id.toString().substring(1)).toString().length;
                const imageFolder = idDigitsCount > 2
                  ? `2${'0'.repeat(4 + idDigitsCount - 3)}`
                    + `${item.prod.id.toString().slice(-idDigitsCount)[idDigitsCount - 3]}00`
                  : '20000000';

                return (
                  <div className="item-container" key={item.prod.id}>
                    <div className="item d-flex">
                      <div className="item__image--mobile" style={
                        bgImageStyle(`/dataImages/productos/${imageFolder}/${item.prod.id}.jpg`)
                        } />
                      <div className="d-flex w-100">
                        <div className="item__image--desktop" style={
                          bgImageStyle(`/dataImages/productos/${imageFolder}/${item.prod.id}.jpg`)
                          } />
                        <div className="item__mid">
                          <div className="item__tagline tagline-01">{item.prod.marca}</div>
                          {categDict['a'] && (
                          <Link onClick={() => userInfo.isCartOpen && dispatch(closeCart())}
                            to={`/${lang}/catalog/${item.prod.codCat}~${slugify(
                              ((categDict as Dictionary<Categ>)[item.prod.codCat] as Categ).nombre,
                            )}/${item.prod.id}`}
                          >
                            <div className="item__title body-light-02">{item.prod.nombre}</div>
                          </Link>
                          )}
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{ __html: item.prod.idsRelacionales }}
                            className="item__ids body-light-01" />
                          <div className="item__measure body-light-01">
                            {terms['measureUnit']}: {item.prod.unidadMedida}
                          </div>
                          <div className="item__price">
                            <span className="item__currency">{item.prod.divisa} </span>
                            {formatNumber(item.prod.precio, lang, true)}
                          </div>
                        </div>
                        <div className="item__end--desktop">
                          <div className="item__total-label body-reg-01">{terms['totalPerItem']}: </div>
                          <div className="item__total-price">
                            <span className="item__total-currency">{item.prod.divisa} </span>
                            {formatNumber(item.prod.precio * item.qty, lang, true)}
                          </div>
                          <div className="item__total-measure item__total-quantity body-light-01">
                            {`${terms['quantity']}: ${item.qty} ${item.prod.unidadMedida}`}
                          </div>
                          {!isQuote ? (
                            <div className="item__state d-flex body-light-01">
                              {terms['state']}:&nbsp;
                              <span className="tagline-01">
                                {item.nombreEstado}
                              </span>
                            </div>
                          ) : (
                            <div className="item__delivery d-flex body-light-01">{
                      terms['deliveryTime']
                        .replace('{{plazoEntrega}}', `${item.prod.plazoEntrega.toString()}`)
                    }
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="item__end--mobile">
                      <div className="item__total-price">
                        <span className="item__total-label body-reg-01">{terms['totalPerItem']}: </span>
                        <span className="item__total-currency">{item.prod.divisa} </span>
                        {formatNumber(item.prod.precio * item.qty, lang, true)}
                        {!isQuote ? (
                          <span className="item__state body-light-01">
                            {terms['state']}:&nbsp;
                            <span className="tagline-01">
                              {item.nombreEstado}
                            </span>
                          </span>
                        ) : (
                          <span className="item__delivery body-light-01">{
                      terms['deliveryTime']
                        .replace('{{plazoEntrega}}', `${item.prod.plazoEntrega.toString()}`)
                    }
                          </span>
                        )}
                      </div>
                      <div className="item__total-measure item__total-quantity body-light-01">
                        {`${terms['quantity']}: ${item.qty} ${item.prod.unidadMedida}`}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="list__left">
              <div className="list__detail">
                {!isQuote && (
                <div className="list__detail-date body-reg-01">
                  {ordenDetail.fecha && formatDateTime(ordenDetail.fecha)}
                </div>
                )}
                <div className="list__detail-id--label body-reg-01">
                  {terms[isQuote ? 'quoteNumber' : 'orderNumber']}
                </div>
                <div className="list__detail-id title-01">
                  {ordenDetail.id}
                </div>
                <div className="list__detail-name--label body-reg-01">
                  {terms['name']}
                </div>
                <div className="list__detail-name tagline-02">
                  {ordenDetail.nombre}
                </div>
                {!isQuote && (
                  <>
                    <div className="list__detail-state--label body-reg-01">
                      {terms['state']}
                    </div>
                    <div className="list__detail-state tagline-02">
                      {ordenDetail.nombreEstado}
                    </div>
                  </>
                )}
                <div className="list__detail-bottom">
                  <button className="list__consult-order btn btn-secondary"
                    type="button"
                  >
                    {terms[isQuote ? 'consultQuote' : 'consultOrder']}
                    <Icon type="mail" width={16} height={16} className="icon" />
                  </button>
                </div>
              </div>
              <OrdersPrice isCart={false} isQuote={isQuote}
                terms={terms} totalPrice={ordenDetail.monto}
                initConfirmBuyModal={initConfirmBuyModal}
                initCopyToCartModal={initCopyToCartModal}
                initDeleteQuoteModal={initDeleteQuoteModal}
               />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
