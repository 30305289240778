import toast from 'react-hot-toast';
import { errorPrefix } from '../../app/globals';

interface ToastIfErrorOptions {
  onError?: () => void, // callback after notifying error
  customMessages?: any, // custom messages for certain error codes, i.e. {'401': 'Invalid Credentials'}
}

const toastIfError = (operation: string, options?: ToastIfErrorOptions) => (response: any) => {
  toast.dismiss(operation);
  if (response.error) {
    const customMessages = options?.customMessages || {};
    console.log(customMessages);
    console.log(response.error.code);
    console.log(customMessages[response.error.code]);
    const message = customMessages[response.error.code]
      || `${errorPrefix} (${operation}): ${response.error.message} [${response.error.code}]`;

    toast.error(message, { id: operation });

    if (options?.onError) {
      options?.onError();
    }
  }
};

export default toastIfError;
