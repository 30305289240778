/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const DefaultLoader = () => (
  <section className="CategoryList">
    <div className="container" style={{ height: '60vh' }}>&nbsp;</div>
  </section>
);

export default DefaultLoader;
