/* eslint-disable react/require-default-props */
/* eslint-disable dot-notation */

import React from 'react';
import { Term } from '../../_types/siteInfoTypes';
import { formatNumber } from '../../common/utils/utils';
import { lang } from '../../app/globals';
import Icon from '../../common/components/Icon';
import './OrderPrice.scss';

interface OrdersPriceProps {
  isCart: boolean,
  isQuote: boolean,
  totalPrice: number,
  terms: Term[],
  initConfirmBuyModal?: () => void,
  initSaveQuoteModal?: () => void,
  initCopyToCartModal?: () => void,
  initDeleteQuoteModal?: () => void,
}

export default function OrdersPrice({
  isCart, isQuote,
  totalPrice,
  terms,
  initConfirmBuyModal,
  initSaveQuoteModal,
  initCopyToCartModal,
  initDeleteQuoteModal,
}: OrdersPriceProps) {

  return (
    <div className="OrderPrice">
      <div className="price__box-title body-medium-02">
        {terms['totalPrice']}
      </div>
      <div className="price__total-price">
        <span className="price__currency">u$s </span>
        &nbsp;{formatNumber(totalPrice, lang, true)}
      </div>
      {isCart && (
      <div className="buttons-container">
        <button className="price__confirm-buy btn btn-danger" type="button"
          onClick={initConfirmBuyModal}
        >
          <Icon type="papel-compra" width={25} height={25} className="icon" />
          {terms['saveOrderButton']}
        </button>
        <button className="price__save-quote btn btn-secondary" type="button"
          onClick={initSaveQuoteModal}
        >
          <Icon type="papel-presupuesto" width={25} height={25} className="icon" />
          {terms['saveQuoteButton']}
        </button>
      </div>
      )}
      {isQuote && (
      <div className="buttons-container">
        <button className="price__confirm-buy btn btn-danger" type="button"
          onClick={initConfirmBuyModal}
        >
          <Icon type="papel-compra" width={25} height={25} className="icon" />
          {terms['saveOrderButton']}
        </button>
        <button className="price__save-quote btn btn-secondary" type="button"
          onClick={initCopyToCartModal}
        >
          <Icon type="editar" width={24} height={24} className="icon" />
          {terms['copyToCartButton']}
        </button>
      </div>
      )}
      <p className="price__info">
        {terms['priceInfo']}
      </p>
      {isQuote && (
      <div className="price__delete-quote">
        <button onClick={initDeleteQuoteModal}
          className="btn btn-dark" type="button"
        >
          <Icon type="papelera" width={25} height={25} />
        </button>
      </div>
      )}
    </div>
  );
}
