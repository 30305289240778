/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { RootState, useAppDispatch, useAppSelector } from '../app/store';
import { UserState, addItemToCart, removeCartItem, closeCart, addOrder,
  Order } from '../user/_userSlice';
import { slugify, bgImageStyle, formatNumber } from '../common/utils/utils';
import { RequestStatus, lang } from '../app/globals';
import Icon from '../common/components/Icon';
import { Categ, Dictionary, getAllCategories, Product, ProdState } from '../prod/_prodSlice';
import ConfirmModal from '../common/components/ConfirmModal';
import { SiteInfo } from '../_types/siteInfoTypes';
import toastIfError from '../common/utils/toastIfError';
import OrdersPrice from '../panels/Orders/OrderPrice';
import '../panels/Orders/OrderDetail.scss';

export default function Cart() {
  const {
    userInfo,
    prodInfo,
    siteInfo,
  } : {
    userInfo: UserState;
    prodInfo: ProdState;
    siteInfo: SiteInfo;
  } = useAppSelector((state: RootState) => ({
    userInfo: state.user,
    prodInfo: state.prod,
    siteInfo: state.content.siteInfo,
  }));

  const { cart } = userInfo;
  const { categDict, categTreeStatus } = prodInfo;
  const { terms } = siteInfo;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (categTreeStatus === RequestStatus.None) {
      dispatch(getAllCategories())
        .then(toastIfError('getAllCategories'));
    }
  }, [categTreeStatus, dispatch]);

  let totalPrice = 0;
  cart.items.forEach((item) => {
    totalPrice += item.prod.precio * item.qty;
  });

  // Modal state variables
  const [showModal, setShowModal] = useState(false);
  const [buyOrder, setBuyOrder] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [removeItemIdx, setRemoveItemIdx] = useState(-1);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState({});
  const [success, setSuccess] = useState(false);

  // Modal bodies
  const modalRemoveItemBody = (name:string, qty:number) => (
    <p>{terms['sureToDelete']} <strong>{name} X{qty}</strong>?</p>
  );
  const modalInputsBody = (isBuyOrder: boolean) => (
    <div>
      <div>{terms['name']}</div>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder={`${terms['name']}...`}
          id={isBuyOrder ? 'order-name' : 'quote-name'} />
      </div>
      <div>{terms['description']}</div>
      <div className="input-group">
        <textarea className="form-control" placeholder={`${terms['description']}...`}
          id={isBuyOrder ? 'order-description' : 'quote-description'} />
      </div>
    </div>
  );
  const modalSaveQuoteSuccessBody = (<p>{terms['quoteSaved']}</p>);
  const modalSaveQuoteFailureBody = (<p>{terms['quoteNotSaved']}</p>);
  const modalConfirmBuySuccessBody = (<p>{terms['orderSaved']}</p>);
  const modalConfirmBuyFailureBody = (<p>{terms['orderNotSaved']}</p>);

  // Modal init functions
  const initSaveQuoteModal = () => {
    setBuyOrder(false);
    setModalBody(modalInputsBody(false));
    setModalTitle(terms['saveQuoteTitle']);
    setShowModal(true);
  };
  const initConfirmBuyModal = () => {
    setBuyOrder(true);
    setModalBody(modalInputsBody(true));
    setModalTitle(terms['saveOrderTitle']);
    setShowModal(true);
  };
  const initRemoveItemModal = (name:string, qty:number, idx:number) => {
    setModalBody(modalRemoveItemBody(name, qty));
    setRemoveItemIdx(idx);
    setModalTitle(terms['confirmRemoveItemTitle']);
    setShowModal(true);
  };

  // Modal callback actions
  const handleModalConfirm = async (idx:number) => {
    if (!refreshModal) {
      if (idx >= 0) {
      // remove item
        dispatch(removeCartItem(idx));
        setShowModal(false);
        setRemoveItemIdx(-1);
      } else {
      // save or edit orders
        const nombre = (document.getElementById(buyOrder
          ? 'order-name' : 'quote-name') as HTMLInputElement)?.value;
        const descripcion = (document.getElementById(buyOrder
          ? 'order-description' : 'quote-description') as HTMLInputElement)?.value;

        if (nombre !== undefined && nombre.length > 0 && cart.items.length > 0) {
          const newOrder: Order = {
            id: cart.id !== undefined ? cart.id : 0,
            items: cart.items,
            nombre,
            descripcion,
            monto: totalPrice,
            tipo: buyOrder ? 'O' : 'P',
            idEstado: 1,
            nombreEstado: '',
          };
          if (!buyOrder) {
          // save quote
            dispatch(addOrder(newOrder))
              .then(toastIfError('addOrder'));
            setModalBody(modalSaveQuoteSuccessBody);
          } else {
          // save order
            dispatch(addOrder(newOrder))
              .then(toastIfError('addOrder'));
            setModalBody(modalConfirmBuySuccessBody);
          }
          setSuccess(true);
          setRefreshModal(true);
        } else {
          setModalBody(buyOrder ? modalConfirmBuyFailureBody : modalSaveQuoteFailureBody);
          setRefreshModal(true);
        }
      }
    }
  };
  const handleModalCancel = () => {
    setShowModal(false);
    setBuyOrder(false);
    setRemoveItemIdx(-1);
    if (refreshModal && success) {
      dispatch(closeCart());
    } else {
      setSuccess(false);
      setRefreshModal(false);
    }
  };

  // handle function for quantity input
  const handleQuantityOnChange = (prod: Product, qty:number, id:string, idx:number) => {
    const diff = parseInt((document.getElementById(id) as HTMLInputElement).value) - qty;

    if (qty + diff > 0) {
      dispatch(addItemToCart({ prod, qty: diff }))
        .then(toastIfError('addItemToCart'));
    } else {
      initRemoveItemModal(prod.nombre, qty, idx);
    }
  };

  return (
    <section className="Cart">
      <ConfirmModal
        onClickConfirm={() => handleModalConfirm(removeItemIdx)}
        onClickCancel={() => handleModalCancel()}
        title={modalTitle}
        body={modalBody}
        show={showModal}
        hasContinue={refreshModal}
      />
      <div className="d-flex flex-column align-items-center w-100">
        <div className="container list">
          <div className="list__heading flex-column">
            <h1 className="list__title title-01">{siteInfo.cart.title}
              <button className="mr-auto btn btn-danger" type="button"
                onClick={() => dispatch(closeCart())}
              >
                {terms['close']}
              </button>
            </h1>
          </div>
          <div className="d-flex list__content">
            <div className="list__items">
              {cart.items
                && cart.items.map((item, idx) => {
                  const idDigitsCount = Number.parseInt(item.prod.id.toString().substring(1)).toString().length;
                  const imageFolder = idDigitsCount > 2
                    ? `2${'0'.repeat(4 + idDigitsCount - 3)}`
                      + `${item.prod.id.toString().slice(-idDigitsCount)[idDigitsCount - 3]}00`
                    : '20000000';

                  return (
                    <div className="item-container" key={item.prod.id}>
                      <div className="item d-flex">
                        <div className="item__image--mobile" style={
                          bgImageStyle(`/dataImages/productos/${imageFolder}/${item.prod.id}.jpg`)
                          } />
                        <div className="d-flex w-100">
                          <div className="item__image--desktop" style={
                            bgImageStyle(`/dataImages/productos/${imageFolder}/${item.prod.id}.jpg`)
                            } />
                          <div className="item__mid">
                            <div className="item__tagline tagline-01">{item.prod.marca}</div>
                            {categDict['a'] && (
                            <Link onClick={() => dispatch(closeCart())}
                              to={`/${lang}/catalog/${item.prod.codCat}~${slugify(
                                ((categDict as Dictionary<Categ>)[item.prod.codCat] as Categ).nombre,
                              )}/${item.prod.id}`}
                            >
                              <div className="item__title body-light-02">{item.prod.nombre}</div>
                            </Link>
                            )}
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{ __html: item.prod.idsRelacionales }}
                              className="item__ids body-light-01" />
                            <div className="item__measure body-light-01">
                              {terms['measureUnit']}: {item.prod.unidadMedida}
                            </div>
                            <div className="item__price">
                              <span className="item__currency">{item.prod.divisa} </span>
                              {formatNumber(item.prod.precio, lang, true)}
                            </div>
                          </div>
                          <div className="item__end--desktop">
                            <div className="item__total-label body-reg-01">{terms['totalPerItem']}: </div>
                            <div className="item__total-price">
                              <span className="item__total-currency">{item.prod.divisa} </span>
                              {formatNumber(item.prod.precio * item.qty, lang, true)}
                            </div>
                            <div className="item__total-measure item__total-quantity body-light-01">
                              {terms['quantity']}:
                              <input type="number" id={`${item.prod.id}-qty`} name="quantity"
                                onChange={() => handleQuantityOnChange(item.prod, item.qty, `${item.prod.id}-qty`, idx)}
                                min="0" max="9999" value={item.qty} />
                              {item.prod.unidadMedida}
                            </div>
                            <div className="item__delivery d-flex body-light-01">{
                              terms['deliveryTime']
                                .replace('{{plazoEntrega}}', `${item.prod.plazoEntrega.toString()}`)
                            }
                              <button className="ml-auto btn btn-dark" type="button"
                                onClick={() => initRemoveItemModal(item.prod.nombre, item.qty, idx)}
                              >
                                <Icon type="papelera" width={25} height={25} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item__end--mobile">
                        <div className="item__total-price">
                          <span className="item__total-label body-reg-01">{terms['totalPerItem']}: </span>
                          <span className="item__total-currency">{item.prod.divisa} </span>
                          {formatNumber(item.prod.precio * item.qty, lang, true)}
                          <span className="item__delivery body-light-01">{
                            terms['deliveryTime']
                              .replace('{{plazoEntrega}}', `${item.prod.plazoEntrega.toString()}`)
                          }
                          </span>
                        </div>
                        <div className="item__total-measure item__total-quantity body-light-01">
                          {terms['quantity']}:
                          <input type="number" id={`${item.prod.id}-qty--mb`} name="quantity"
                            onChange={() => handleQuantityOnChange(item.prod, item.qty, `${item.prod.id}-qty--mb`, idx)}
                            min="0" max="9999" value={item.qty} />
                          {item.prod.unidadMedida}
                          <button className="ml-auto btn btn-dark" type="button"
                            onClick={() => initRemoveItemModal(item.prod.nombre, item.qty, idx)}
                          >
                            <Icon type="papelera" width={25} height={25} />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="list__right">
              <OrdersPrice isCart isQuote={false}
                terms={terms} totalPrice={totalPrice}
                initConfirmBuyModal={initConfirmBuyModal}
                initSaveQuoteModal={initSaveQuoteModal}
               />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
