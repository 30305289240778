import React from 'react';
import { PageInfo, PanelInfo } from '../_types/pageTypes';
import PanelFactory from './panelFactory';

export default function PanelWrapper({ pageInfo, panel } : {
  pageInfo: PageInfo,
  panel: PanelInfo<any>,
}) {
  const panelComponent = PanelFactory(panel.type);
  if (!panelComponent) {
    return <div className="text-danger">No React component implemented for panel '{panel.type}' (front-end)</div>;
  }

  return (
    React.createElement(panelComponent, { pageInfo, panel })
  );
}
