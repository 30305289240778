import React from 'react';
import { Link } from 'react-router-dom';
import './SimpleBannerPanel.scss';
import { PanelInfo } from '../_types/pageTypes';
import { SimpleBannerPanelProps } from '../_types/simpleBannerPanelTypes';

interface SimpleBannerPanelContent extends SimpleBannerPanelProps {}

export default function SimpleBannerPanel({ panel }: {
  panel: PanelInfo<SimpleBannerPanelContent>
}) {
  const { content } = panel;
  if (!content.banner1Image || !content.banner2Image) {
    return null;
  }
  const disableForNow = false;

  if (disableForNow) {
    return null;
  }

  return (
    <section
      className="SimpleBannerPanel panel"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <Link to={content.banner1Url}>
              <img className="image-block" src={content.banner1Image} alt="" />
            </Link>
          </div>
          {content.banner2Image && (
          <div className="col-sm">
            <Link to={content.banner2Url}>
              <img className="image-block" src={content.banner2Image} alt="" />
            </Link>
          </div>
          )}
        </div>
      </div>
    </section>
  );
}
