import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { SelectFilter, SelectOption } from '../../prod/_prodSlice';
import { slugify } from '../../common/utils/utils';
import Icon from '../../common/components/Icon';
import './ProductFilter.scss';

function ProductFilter({ filter, filterValues, handleSelectFilter }
:{ filter:SelectFilter, filterValues:object | null,
  handleSelectFilter:(event:any, filterName:string, isText?:boolean) => void }) {

  const [open, setOpen] = useState(false);
  let isChecked: boolean = false;

  if (filter.options.length < 2) {
    return null;
  }

  return (
    <div className="ProductFilter filter" key={`${slugify(filter.name)}-filter`}>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="shadow-none body-medium-02"
        variant="dark"
      >
        {filter.name}
        <div className="accordion-icon">
          <Icon type="arrow-down--white" width={16} height={16} />
        </div>
      </Button>
      <Collapse in={open}>
        <div className="filter__options body-light-01">
          {filter.hasTextInput && (
          <input type="text" placeholder="Buscar..." className="w-100"
            onChange={(e) => handleSelectFilter(e, filter.name, true)} />
          )}
          {filter.options
          && (
          <div className={`options-container ${filter.options.length > 6 ? 'scrollable' : ''}`}>
            {
              filter.options.map((opt: SelectOption) => {
                if (filterValues && filterValues[`${slugify(filter.name)}`]) {
                  isChecked = (filterValues[`${slugify(filter.name)}`].value as string).includes(opt.key);
                } else {
                  isChecked = false;
                }

                return (
                  <div className="filter__option" key={opt.key}>
                    <input type="checkbox"
                      value={opt.key}
                      checked={isChecked}
                      onChange={(e) => handleSelectFilter(e, filter.name)}
                />
                    {opt.label}
                  </div>
                );
              })
}
          </div>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default ProductFilter;
