import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.scss';

export interface BreadcrumbContent {
  urls : string[];
  names: string[];
}

export function Breadcrumb(props: BreadcrumbContent) {
  const { urls, names } = props;

  /* if (urls.length < 2) {
    return null; // do not show if only "Home" in breadcrumb
  } */

  return (
    <div className="Breadcrumb container">
      <ul>
        <Link className="body-reg-01" to={urls[0]}>{names[0]}</Link>
        {urls.slice(1).map((url, idx) => (
          <li key={url} className="capitalize body-reg-01">
            <Link className="body-reg-01" to={url}>{names[idx + 1]}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
