/* eslint-disable dot-notation */

import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { RootState, useAppSelector, useAppDispatch } from '../../app/store';
import { UserState, deleteQuote } from '../../user/_userSlice';
import { SiteInfo } from '../../_types/siteInfoTypes';
import { formatDateTime, formatNumber } from '../../common/utils/utils';
import ConfirmModal from '../../common/components/ConfirmModal';
import Icon from '../../common/components/Icon';
import { lang } from '../../app/globals';
import toastIfError from '../../common/utils/toastIfError';
import './OrdersList.scss';

export default function OrdersList({ title, isQuote, segments }
:{ title:string, isQuote:boolean, segments:string[] }) {

  const {
    userInfo,
    siteInfo,
  } : {
    userInfo: UserState;
    siteInfo: SiteInfo;
  } = useAppSelector((state: RootState) => ({
    userInfo: state.user,
    siteInfo: state.content.siteInfo,
  }));

  const { terms } = siteInfo;

  const dispatch = useAppDispatch();

  // returns user orders or quotes as appropriate
  const getItems = (areQuotes: boolean) => {
    if (areQuotes) return userInfo.presupuestos;
    return userInfo.ordenes;
  };

  const [showModal, setShowModal] = useState(false);
  const [deleteQuoteId, setDeleteQuoteId] = useState(-1);
  const [modalBody, setModalBody] = useState({});

  const modalDeleteQuoteBody = (id: number, name:string) => (
    <p>{terms['sureToDelete']} <strong className="quote-id">#{id}</strong> <strong>{name}</strong>?</p>
  );

  const initDeleteQuoteModal = (id: number, name:string) => {
    setDeleteQuoteId(id);
    setModalBody(modalDeleteQuoteBody(id, name));
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    dispatch(deleteQuote(deleteQuoteId))
      .then(toastIfError('deleteQuote'));
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setDeleteQuoteId(-1);
    setShowModal(false);
  };

  return (
    <section className={`${isQuote ? 'QuotesList' : 'OrdersList'} panel`}>
      <ConfirmModal
        onClickConfirm={() => handleModalConfirm()}
        onClickCancel={() => handleModalCancel()}
        title="Eliminar presupuesto"
        body={modalBody}
        show={showModal}
      />
      <div className="container">
        <div className="panel__heading">
          <div className="panel__title title-01">{title}</div>
        </div>
        <div className="table">
          <table>
            <thead className="table__head body-medium-01">
              <tr>
                <td>{terms[isQuote ? 'quoteNumber' : 'orderNumber']}</td>
                <td className="text-uppercase">{terms['name']}</td>
                <td className="text-uppercase">{terms['creationDate']}</td>
                <td className="text-uppercase">{terms['amount']} US$</td>
                {isQuote ? (<td className="item__delete-cell text-uppercase">{terms['delete']}</td>)
                  : (<td className="text-uppercase">{terms['state']}</td>)}
              </tr>
            </thead>
            <tbody className="table__items">
              {getItems(isQuote).map((item) => (
                <tr className="item body-light-01" key={item.id}>
                  <td className="body-reg-01">
                    <Link to={`./${segments[segments.length - 1]}/${item.id}`}>
                      {item.id}
                    </Link>
                  </td>
                  <td>
                    <Link to={`./${segments[segments.length - 1]}/${item.id}`}>
                      {item.nombre}
                    </Link>
                  </td>
                  {item.fecha && (
                    <td>{formatDateTime(item.fecha)}</td>
                  )}
                  <td>{formatNumber(item.monto, lang, true)}</td>
                  {isQuote ? (
                    <td className="item__delete-cell">
                      <button onClick={() => initDeleteQuoteModal(item.id, item.nombre)}
                        className="btn" type="button"
                      >
                        <Icon type="papelera--black" width={25} height={25} />
                      </button>
                    </td>
                  ) : (
                    <td className="text-uppercase">
                      {item.nombreEstado}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table--mobile">
        <div className="container">
          <table>
            {getItems(isQuote).map((item) => (
              <React.Fragment key={`${item.id}--mb`}>
                <thead className="table__head body-medium-01">
                  <tr>
                    <td>{terms['quoteNumber']}</td>
                    <td className="body-reg-01">
                      <Link to={`./${segments[segments.length - 1]}/${item.id}`}>
                        <div>{item.id}</div>
                      </Link>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="body-light-01">
                    <td className="text-uppercase">{terms['name']}</td>
                    <td>
                      <Link to={`./${segments[segments.length - 1]}/${item.id}`}>
                        <div>{item.nombre}</div>
                      </Link>
                    </td>
                  </tr>
                  <tr className="body-light-01">
                    <td className="text-uppercase">{terms['creationDate']}</td>
                    {item.fecha && (
                    <td>{formatDateTime(item.fecha)}</td>
                    )}
                  </tr>
                  <tr className="body-light-01">
                    <td className="text-uppercase">{terms['amount']}</td>
                    <td>{formatNumber(item.monto, lang, true)}</td>
                  </tr>
                  {isQuote ? (
                    <tr className="body-light-01">
                      <td className="item__delete-cell text-uppercase" colSpan={2}>
                        <button onClick={() => initDeleteQuoteModal(item.id, item.nombre)}
                          className="btn" type="button"
                        >
                          <Icon type="papelera--black" width={25} height={25} />
                          {terms['delete']}
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr className="body-light-01">
                      <td className="text-uppercase">
                        {item.nombreEstado}
                      </td>
                    </tr>
                  )}
                </tbody>
              </React.Fragment>
            ))}
          </table>
        </div>
      </div>
    </section>
  );
}
