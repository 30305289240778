/* eslint-disable no-bitwise */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NavDropdown, Nav, Dropdown, Navbar } from 'react-bootstrap';
import './Header.scss';
import { mediaUrl, useQuery } from '../common/utils/utils';
import { RootState, useAppDispatch, useAppSelector } from '../app/store';
import { openCart, logout, UserState, closeCart } from '../user/_userSlice';
import { Categ, ProdState } from '../prod/_prodSlice';
import { lang } from '../app/globals';
import { SiteInfo } from '../_types/siteInfoTypes';
import Icon from '../common/components/Icon';
// import { AppRoles } from '../common/utils/authStorage';

function Header() {

  const {
    siteInfo,
    prodInfo,
    userInfo,
  } : {
    siteInfo: SiteInfo;
    prodInfo: ProdState;
    userInfo: UserState;
  } = useAppSelector((state: RootState) => ({
    siteInfo: state.content.siteInfo,
    prodInfo: state.prod,
    userInfo: state.user,
  }));

  const dispatch = useAppDispatch();

  const search = useQuery().get('q');
  const [searchText, useSearchText] = useState(search);
  const history = useHistory();

  const userDropdownTitle = (
    <>
      <div className="user-icon">
        <Icon type="user" width={25} height={25} />
      </div>
      {`${userInfo.nombre} ${userInfo.apellido}`}
      <div className="dropdown-icon">
        <Icon type="arrow-down" width={16} height={16} />
      </div>
    </>
  );

  const closeElements = () => {
    document.dispatchEvent(new MouseEvent('click')); // forces close of Nav dropdowns
    if (userInfo.isCartOpen) {
      dispatch(closeCart());
    }
  };

  return (
    <header className="Header nav-container w-100">
      <Navbar expand="lg">
        <div className="container">
          <Link className="header__logo navbar-brand" to={'/' + lang}
            onClick={() => userInfo.isCartOpen && dispatch(closeCart())}
          >
            <img
              className="logo"
              alt="logo"
              src={mediaUrl(siteInfo.header.logoImage)}
            />
            <Icon type="bileran-logo--red" width={45} height={45} className="mobile-logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="w-100 mr-auto">
              <Dropdown className="header__categ-dropdown">
                <Dropdown.Toggle variant="dark" id="dropdown-basic" className="shadow-none">
                  Categorías
                  <div className="dropdown-icon">
                    <Icon type="arrow-down--white" width={16} height={16} />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {prodInfo.featuredCategs
                    && prodInfo.featuredCategs.map((cat: Categ) => (
                      <Link to={`/${lang}/catalog/${cat.cod}`} key={cat.id}
                        onClick={closeElements}
                        className="navbar-link dropdown-item"
                      >
                        {cat.nombre}
                      </Link>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
          <div className="header__search flex-grow-1">
            <div className="input-group">
              <form onSubmit={(e) => {
                if (userInfo.isCartOpen) {
                  dispatch(closeCart());
                }
                history.push(searchText ? `/${lang}/catalog?q=${searchText}` : `/${lang}/catalog`);
                e.preventDefault();
              }}
              >
                <input className="form-control" type="search"
                  placeholder={siteInfo.header.searchPlaceholder} aria-label="Search"
                  onChange={(e) => useSearchText(e.target.value)} defaultValue={searchText as string} />
                <div className="input-group-append">
                  <button className="btn" type="submit">
                    <Icon type="search" width={22} height={22} />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="w-100 mr-auto">
              <NavDropdown title={userDropdownTitle} className="header__user-dropdown" id="basic-nav-dropdown">
                {siteInfo.header.headerLinks
                  && siteInfo.header.headerLinks.map((lnk: any) => {
                    if (lnk.url === '(logout)') {
                      return (
                        <NavDropdown.Item href="#" key={lnk.text} onClick={() => dispatch(logout())}
                          className="navbar-link"
                        >
                          {lnk.text}
                        </NavDropdown.Item>
                      );

                    }
                    return (
                      <Link to={`/${lang}${lnk.url}`} key={lnk.text}
                        onClick={closeElements}
                        className="navbar-link dropdown-item"
                      >
                        {lnk.text}
                      </Link>
                    );
                  })}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <button className="header__cart btn ml-auto shadow-none"
            type="button" onClick={() => dispatch(openCart())}
          >
            {userInfo.cart.items.length > 0 && (<div className="cart-count">{userInfo.cart.items.length}</div>)}
            <Icon type="carrito" width={31} height={28} />
          </button>
        </div>
      </Navbar>
    </header>
  );
}

export default Header;
